<template>
    <div>
        <div v-if="$can('transaccion.menu.nueva.venta')">
            <ModuloVenta
                :nuevaventanaventaid="parseInt(this.ventanaID)" 
            />
        </div>
    </div>
</template>

<script>
    import ModuloVenta from './Modulos/ModuloVenta.vue'
    export default {

        props: [
            'ventanaID'
        ],

        components: {
            ModuloVenta
        }
    }
</script>
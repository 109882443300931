<template>
    <div>
        <a-row type="flex" :gutter="[8,8]">
            <a-col class="flex-card-left" flex="1 1 75%">
                <a-row type="flex" :gutter="[2,2]">
                    <a-col flex="1 0 100%">
                        <a-button 
                            v-if="$can('estimacion.boton.opciones.de.cotizacion')"
                            size="small" 
                            @click="habilitarOpciones()"
                            >
                            <span v-if="!isBlock"><a-icon type="lock" /> Habilitar</span>
                            <span v-if="isBlock"><a-icon type="unlock" /> Deshabilitar</span>
                        </a-button>

                        <a-button 
                            v-if="$can('estimacion.busqueda.de.producto.en.cotizacions')"
                            size="small" 
                            @click="openModalBuscarProducto()"
                            >
                            <a-icon type="search" /> Buscar Producto
                        </a-button>
                        
                        <a-button 
                            size="small" 
                            type="primary" 
                            @click="openVentanaNueva()"
                            >
                            <a-icon type="desktop" /> Nueva Ventana {{ nuevaventanacotizacionid }}
                        </a-button>
                            
                        <a-button 
                            v-if="$can('estimacion.agregar.nuevo.producto.cotizacion')"
                            size="small" 
                            type="primary" 
                            @click="openModalProducto(null, 'agregar')"
                            >
                            <a-icon type="plus" /> Nuevo Producto
                        </a-button>
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[2,2]">                    
                    <a-col flex="1 0 10%" v-if="$can('estimacion.cotizacion.por.usuarios')">
                        <!--SELECT USUARIO-->
                        <span class="d-sm-block"><strong>Usuario:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.usuario" 
                            @change="onChangeUsuario" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                        </a-select>
                        <!--SELECT USUARIO-->
                    </a-col>

                    <a-col flex="1 0 10%" v-if="$can('estimacion.cotizacion.por.sucursales')">
                        <!--SELECT ALMACEN-->
                        <span class="d-sm-block"><strong>Sucursal:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.almacen_id" 
                            @change="onChangeAlmacen" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                        <!--SELECT ALMACEN-->
                    </a-col>

                    <a-col flex="1 0 10%">
                        <span class="d-sm-block"><strong>Tipo Precio:</strong></span>
                        <a-select 
                            :value="consulta.tipoprecioventaID" 
                            @change="onChangeTipoPrecioVenta" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in listatipoprecioventa" :key="index" :value="value.tipoprecioventa_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 20%">
                        <span class="d-sm-block"><strong>Observaciones:</strong></span>
                        <a-textarea v-model="consulta.observaciones" placeholder="Observaciones de Cotizacion" :rows="1" />
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[2,2]">
                    <a-col class="flex-card-codigo" flex="1 0 25%">
                        <center>
                            <label>CODIGO BARRAS: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F1)</span>
                            </label>
                        </center>
                        <SelectCodigoBarras
                            :almacenid="1"
                            :tipoprecioventaid="1"
                            :tiposalidaid="1"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successProductoStockCodigo="successProductoCodigo"
                        />      
                    </a-col>

                    <a-col class="flex-card-producto" flex="1 0 70%">
                        <center>
                            <label>SELECCIONA PRODUCTOS PARA LA COTIZACION: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F2)</span> &nbsp;&nbsp; 
                                <span style="font-weight:700; font-size:11px; color: rgb(32, 80, 129);">Cerrar </span>
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(ESC)</span>
                            </label>
                            &nbsp;&nbsp; 
                            <a-button 
                                size="small"
                                :type="(!isFactura) ? 'dashed' : 'primary'"
                                @click="habilitarFacturacion()"
                                >
                                <span v-if="!isFactura"><a-icon type="file-done" /> Cotizando</span>
                                <span v-if="isFactura"><a-icon type="file-done" /> Facturando</span>
                            </a-button>
                        </center>
                        <SelectScrollProducto
                            ref="selectScrollProducto" 
                            :almacenid="parseInt(consulta.almacen_id)"
                            :tipoprecioventaid="parseInt(consulta.tipoprecioventaID)"
                            :tiposalidaid="parseInt(consulta.tiposalida_id)"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successScrollProducto="successProducto"
                        />
                    </a-col>

                    <a-col class="flex-card-tabla" flex="1 1 100%">
                        <TablaCotizaciones 
                            ref="tablacotizaciones"
                            :updateventa="updateventa"
                            :estadomoduloventa="estadomoduloventa"
                            :nuevaventanacotizacionid="nuevaventanacotizacionid"
                            :listaproducto="productoElegido"
                            @subtotal="successSubtotal"
                            @descuento="successDescuento"
                            @total="successTotal"
                            @productos="successTablaProducto"
                        />
                    </a-col>
                </a-row>
            </a-col>
            
            <a-col class="flex-card-right" flex="1 0 25%">
                <div style="height: 100px; background-color:#000000; overflow: auto;">
                    <center><label style="color:#fff;">Precio Total</label></center>
                    <center><font size="7" color="#0ed104"><strong><p>{{ ViewSubtotal }}</p></strong></font></center>
                </div>

                <template v-if="config.habilitardescuentomanualventa == 'DESACTIVADO'">
                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuento > 0">
                        <center><label style="color:#fff;">Precio Total con Descuento</label></center>
                        <center><font size="7" color="#ddf7ff"><strong><p>{{ ViewSubtotalTotalConDescuento }}</p></strong></font></center>
                    </div>
                </template>

                <template v-if="config.habilitardescuentomanualventa == 'ACTIVADO'">
                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuento > 0">
                        <center><label style="color:#fff;">Precio Subtotal con Descuento</label></center>
                        <center><font size="7" color="#ddf7ff"><strong><p>{{ ViewSubtotalTotalConDescuento }}</p></strong></font></center>
                    </div>
                </template>

                <h4 class="titulo-venta-color"><center>NRO DE COTIZACION</center></h4>
                <h2 class="codigodeventa">{{ consulta.codigoVenta }}</h2>

                <div class="row">
					<div class="col-md-12">
						<label class="d-sm-block">Forma de Pago:</label>
						<a-select :value="consulta.formapago" @change="onChangeFormaPago" style="width: 100%">
							<a-select-option v-for="(value, index) in lista_forma_pago" :key="index" :value="value.nombre"> {{ value.nombre }}</a-select-option>
						</a-select>
					</div> 
				</div>

				<div class="row">
					<div class="col-md-12">
						<label class="d-sm-block">Validez de Cotización:</label>
						<a-select :value="consulta.validezcotizacion" @change="onChangeValidezPago" style="width: 100%">
							<a-select-option v-for="(value, index) in lista_validez_cotizacion" :key="index" :value="value.nombre"> {{ value.nombre }}</a-select-option>
						</a-select>
					</div> 
				</div>

				<div class="row">
					<div class="col-md-12">
						<label class="d-sm-block">Tiempo de Entrega:</label>
						<a-select :value="consulta.tiempoentrega" @change="onChangeTiempoEntrega" style="width: 100%">
							<a-select-option v-for="(value, index) in lista_tiempo_entrega" :key="index" :value="value.nombre"> {{ value.nombre }}</a-select-option>
						</a-select>
					</div>
				</div>

				<div class="row">
					<div class="col-md-12">
						<label class="d-sm-block">Cargos Extra (F3):</label>
						<strong>
							<a-input 
                                v-model.trim="consulta.cargoextra" 
                                @click="consulta.cargoextra = ''"  
                                id="ImportePagar" 
                                placeholder="0.00"  
                                style="height: 50px; font-size: 27px;  border-width: 4px; border-width: 1px; border-color: var(--element); border-style: solid;"
                            />
						</strong>
					</div>
				</div>

                <br>

                <div class="input-group col-md-12 col-xs-12">
					<a-button block @click="openModalCliente()" id="AgregarCliente">
						<a-icon type="user" /> AGREGAR CLIENTE  (F6)
					</a-button>
				</div>

				<div style="background-color:#000000;">
					<center><font size="4" color="#0ed104"><strong><p>NIT : {{ consulta.clienteNit }}</p></strong></font></center>
					<center><font size="3" color="#0ed104"><strong><p>{{ consulta.clienteNombre }}</p></strong></font></center>
				</div>

                <template v-if="estadomoduloventa === 'AGREGAR'">
                    <div class="box">
                        <button 
                            type="button" 
                            id="EnviarInformacion" 
                            class="btn btn-block btn-lg" 
                            style="background-color:var(--element);" 
                            @click.once="realizarCotizacion()"
                            :key="buttonKeyCotizacion"
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white; font-size:16px;">REALIZAR COTIZACION <span style="font-size:13px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
                    </div>
                </template>

                <template v-if="estadomoduloventa === 'EDITAR'">
                    <button 
                        type="button" 
                        id="EnviarInformacion" 
                        class="btn btn-block btn-lg" 
                        style="background-color:var(--element);"  
                        @click.once="editarCotizacion()" 
                        :key="buttonKeyEditarCotizacion"
                        >
                        <font size=4>
                            <center>
                                <strong style="color:white;">EDITAR COTIZACION <span style="font-size:13px;">(F9)</span></strong>
                            </center>
                        </font>
                    </button>
                </template>

                <div class="box">
					<button 
                        type="button" 
                        id="EnviarInformacion" 
                        class="btn btn-block btn-lg" 
                        style="background-color:#ff3333;" 
                        @click="eliminarListaProductos()" 
                        >
                        <font size=2>
                            <center>
                                <strong style="color:white;">ACTUALIZAR LISTA DE ITEMS</strong>
                            </center>
                        </font>
                    </button>
				</div>
            </a-col>
        </a-row>

        <!--PRODUCTO PROVEEDOR-->
		<a-modal
            v-model="modalBuscarProducto"
            :title="false"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1250"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <ModuloProductoProveedor 
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :statecodigobarradefault="config.statecodigobarradefault" 
                    :disabledvencimiento="config.disabledvencimiento" 
                    :preciocifradecimal="config.preciocifradecimal" 
                    :preciocifraentera="config.preciocifraentera" 
                    :precioreglatres="config.precioreglatres"  
                    :sessionuserid="config.userid"
                    :monedasimbolo="config.monedasimbolo"
                    :almacenid="config.almacenid"			
                /> 
            </div>

            <template slot="footer">
                <a-button key="back" @click="closeModalBuscarProducto()">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTO PROVEEDOR-->

        <!--CLIENTE-->
		<a-modal
            v-model="modalCliente"
            title="GESTION CLIENTES"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <div style="background-color:#000000;">
					<center><font size="7" color="#0ed104"><strong><p>NIT : {{ consulta.clienteNit }}</p></strong></font></center>
					<center><font size="6" color="#0ed104"><strong><p>{{ consulta.clienteNombre }}</p></strong></font></center>
				</div>
				<div class="form-group col-md-12 col-xs-12">
					<label>Buscar Cliente  <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F4)</span></label>
					<SelectScrollCliente 
                        @successScrollCliente="onChageScrollCliente"
                    />
				</div>
				<div class="form-group col-md-12 col-xs-12">
					<label>NIT /CI: <span style="font-weight:700; font-size:12px; color: rgb(32, 80, 129);">Presione ENTER para consultar</span></label>
					<a-input 
						size="large"
						v-model="consulta.clienteNit" 
						@keypress.enter="buscarCliente($event)" 
						placeholder="5744678011" 
						autofocus
					/>
				</div>
				<div class="form-group col-md-12 col-xs-12">
					<label>Nombre del Cliente o Razón Social: </label>
					<a-input 
						size="large"
						v-model="consulta.clienteNombre" 
						placeholder="Nombre Cliente"
					/>
				</div>
            </div>

            <template slot="footer">
				<button 
                    type="button" 
                    class="btn btn-danger" 
                    id="CerrarModalCliente" 
                    ref="CerrarModalCliente" 
                    @click="modalCliente = false"
                    >
                    <i class="fa fa-ban"></i> CERRAR (ESC)
                </button>
				
                <button 
                    type="button" 
                    class="btn btn-primary"
                    style="background-color:var(--element);" 
                    @click="modalCliente = false"
                    id="GuardarCliente"
                    > 
                    <i class="fa fa-check"></i> REGISTRAR CLIENTE (F8)
                </button>
            </template>
        </a-modal>
        <!--CLIENTE-->

        <!--PRODUCTOS-->
		<a-modal
            v-model="modalProducto"
            title="GESTION DE PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="520"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteProducto
                    :tipoproducto="producto.tipomodal"
                    :dataproducto="producto.producto"
                    :almacenid="parseInt(consulta.almacen_id)"
                    :proveedorid="consulta.proveedor_id"
                    :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
                    @successProducto="dataSuccessProducto"
                />
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalProducto = false">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTOS-->

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
	import moment from 'moment'
    import Swal from 'sweetalert2'
	import * as utils from '@/utils/utils'
    import Cookies from 'js-cookie'
    import { mapGetters, mapActions } from 'vuex'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import 'moment/locale/es'
    import SelectScrollProducto from '../../../components/SelectScrollProducto.vue'
    import SelectCodigoBarras from '../../../components/SelectCodigoBarras.vue'
    import TablaCotizaciones from '../../../components/TablaCotizaciones.vue'
    import SelectScrollCliente from '../../../components/SelectScrollCliente.vue'
    import ComponenteProducto from '../../Inventario/Components/ComponenteProducto.vue'
	import ModuloProductoProveedor from '../../Inventario/Modulos/ModuloProductoProveedor.vue'

    import {
        ALMACEN_SISTEMA_KEY_ID,
        SISTEMA_USUARIO,
        STORAGE_LISTA_SCROLL_CLIENTE
    } from '@/utils/constants'

    export default {

        props: [
            'nuevaventanacotizacionid',
            'estadomoduloventa',
            'updateventa'
        ],

        components: {
            SelectScrollProducto,
            SelectCodigoBarras,
            TablaCotizaciones,
            SelectScrollCliente,
            ModuloProductoProveedor,
            ComponenteProducto
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                utils,
                productoElegido: {},
                consulta: {
                    cotizacionID: '',
                    almacen_id: '',
                    proveedor_id: '',
                    tipoprecioventaID: '',
                    usuario: 'Seleccionar',
                    tiposalida_id: 1,
                    tipo_venta_id: '',
                    observaciones: '',
                    fechaCredito: '',
                    fechadeventa: '',
                    productos: [],
                    subtotal: '',
                    descuento: '',
                    total: '',
                    descuentoPorcentajeManual: 'Seleccionar Descuento',
                    descuentoPorcentajeSeleccion: '',
                    descuentoPorcentajeNombre: '',
                    descuentoMonedaMonto: '',
                    clienteID: '',
                    clienteNombre: 'SIN NOMBRE',
                    clienteNit: '0',
                    codigoVenta: '',
                    importePagar: '',
                    cambio: '',
                    formapago: 'Pago en Efectivo',
                    validezcotizacion: '5 días hábiles',
                    tiempoentrega: '12 Horas',
                    cargoextra: '0.00'
                },
                producto: {
                    producto: {},
                    tipomodal: ''
                },
                lista_forma_pago: [
                    { id: '1', nombre: 'Pago en Efectivo' },
                    { id: '2', nombre: 'Pago a Credito' }
                ],
				lista_validez_cotizacion: [
                    { id: '1', nombre: '5 días hábiles' },
					{ id: '2', nombre: '10 días hábiles' },
					{ id: '3', nombre: '15 días hábiles' },
					{ id: '4', nombre: '20 días hábiles' },
					{ id: '5', nombre: '25 días hábiles' },
					{ id: '6', nombre: '30 días hábiles' },
					{ id: '7', nombre: '60 días hábiles' },
					{ id: '8', nombre: '90 días hábiles' }
                ],
				lista_tiempo_entrega: [
					{ id: '1', nombre: '1 Hora' },
                    { id: '2', nombre: '3 Horas' },
					{ id: '3', nombre: '6 Horas' },
					{ id: '4', nombre: '12 Horas' },
					{ id: '5', nombre: '24 Horas' },
					{ id: '6', nombre: '48 Horas' },
					{ id: '7', nombre: '72 Horas' },
					{ id: '8', nombre: '5 Dias' },
					{ id: '9', nombre: '6 Dias' },
					{ id: '10', nombre: '1 Semana' },
					{ id: '11', nombre: '2 Semanas' },
					{ id: '12', nombre: '3 Semanas' },
					{ id: '13', nombre: '1 Mes' },
					{ id: '14', nombre: '2 Meses' },
					{ id: '15', nombre: '3 Meses' }
                ],
                isBlock: false,
                isFactura: false,
                spinnerloading: false,
                habilitardescuentomanualventa: false,
                habilitarcredito: false,
                estadoArqueoCajaCerrada: false,
                modalCliente: false,
                modalBuscarProducto: false,
                modalProducto: false,
                buttonKeyPago: 100,
                buttonKeyCredito: 200,
                buttonKeyCliente: 300,
                buttonKeyCotizacion: 400,
                buttonKeyEditarCotizacion: 500,
                isDisabledButton: this.$can('manager.arqueo.de.caja')
            }
        },

        mounted () {
            this.cargarAlmacenProductos()
            this.getAlmacenListar()
            this.cargarUsuarios()
            this.cargarTipoPrecioVenta()
            this.cargarTipoVenta()
            this.cargarDatosCotizacion()
            this.cargarProveedor()
            this.getListaScrollMasivoCliente()
            this.comandkeys()

            if (this.estadomoduloventa === 'EDITAR') {
				setTimeout(function () {
                    this.consulta.cotizacionID = this.updateventa.cotizacion_id
                    this.consulta.almacen_id = this.updateventa.almacenID.toString()
                    this.consulta.usuario = this.updateventa.usuario
                    this.consulta.observaciones = this.updateventa.observacion
                    this.consulta.subtotal = this.updateventa.subtotal
                    this.consulta.descuento = this.updateventa.descuento
                    this.consulta.total = this.updateventa.total
                    this.consulta.clienteID = this.updateventa.clienteID
                    this.consulta.clienteNombre = this.updateventa.cliente.nombreFactura
                    this.consulta.clienteNit = this.updateventa.cliente.nitFactura
                    this.consulta.codigoVenta = this.updateventa.codigo
                    this.consulta.formapago = this.updateventa.formapago
                    this.consulta.validezcotizacion = this.updateventa.validez
                    this.consulta.tiempoentrega = this.updateventa.tiempoEntrega
                    this.consulta.cargoextra = this.updateventa.servicioExtra
                }.bind(this), 500)
			}
        },

        computed: {
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('tipoprecioventa', ['listatipoprecioventa']),
            ...mapGetters('plancuenta', ['tipodeventas']),
            ...mapGetters('venta', ['porcentajeventas']),
            ...mapGetters('proveedor', ['proveedores']),

            calculoCambio () {
                const importeTotal = parseFloat(this.consulta.importePagar)
                const subtotal = this.consulta.total
                return (!isNaN(importeTotal) && !isNaN(subtotal) && importeTotal > 0) ? Math.max(0, importeTotal - subtotal) : 0
            },

            ViewSubtotal () {
                return utils.formatMoney(this.consulta.subtotal, this.config.ventadecimal, '.', '')
            },

            ViewSubtotalTotalConDescuento () {
                return utils.formatMoney(this.consulta.total, this.config.ventadecimal, '.', '')
            },

            ViewCalculoCambio () {
                return utils.formatMoney(this.calculoCambio, this.config.ventadecimal, '.', '')
            }
        },

        methods: {
            ...mapActions('producto', ['getListaScrollMasivoProducto']),
            ...mapActions('almacen', ['getAlmacenListar']),
            ...mapActions('usuario', ['getUsuarioListar']),
            ...mapActions('tipoprecioventa', ['getTipoPrecioVenta']),
            ...mapActions('plancuenta', ['getTipoVentas']),
            ...mapActions('cotizacion', ['getDatosCotizacion', 'guardarCotizacion', 'actualizarCotizacion']),
            ...mapActions('cliente', ['getListaScrollMasivoCliente']),
            ...mapActions('proveedor', ['getProveedorListado']),

            comandkeys () {
				var self = this

				var ENTER_KEY_CLOSE = 27
				var ENTER_KEY_SUB_CLOSE = 27
				var ENTER_KEY_CONFIRMAR = 13

				var ENTER_KEY_CODIGO_BARRA = 112
				var ENTER_KEY_LISTA = 113
				var ENTER_KEY_IMPORTE = 114
				var ENTER_KEY_CLIENTE = 115
				var ENTER_KEY_TIPO_SALIDA = 116
				var ENTER_KEY_AGREGAR_CLIENTE = 117
				var ENTER_KEY_AGREGAR_SOLICITANTE = 118
				var ENTER_KEY_GUARDAR_CLIENTE = 119
				var ENTER_KEY_GUARDAR_SALIDA = 120
				var ENTER_KEY_MAYUS = 16
				var ENTER_KEY_O = 79
				var ENTER_KEY_J = 74
				var ENTER_KEY_CTRL = 17
				var ENTER_KEY_PLUS = 61

				document.addEventListener('keydown', function (e) {
                    const code = e.keyCode || e.which
                    const habilitarControlCodigoBarras = self.config.habilitarcontrolcodigobarras === 'ACTIVADO'
                    const codesToPrevent = [74, 61, 17, 27, 112, 113, 114, 115, 116, 117, 118, 119, 120]

                    if ((habilitarControlCodigoBarras && codesToPrevent.includes(code)) || (!habilitarControlCodigoBarras && codesToPrevent.slice(1).includes(code))) {
                        e.preventDefault()
                        
                        switch (code) {
                            case ENTER_KEY_TIPO_SALIDA:
                                console.log('F5')
                                break
                            case ENTER_KEY_J:
                            case ENTER_KEY_PLUS:
                            case ENTER_KEY_CTRL:
                            case ENTER_KEY_CODIGO_BARRA:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                break
                            case ENTER_KEY_CLOSE:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                document.getElementById('CerrarModalCliente').click()
                                break
                            case ENTER_KEY_SUB_CLOSE:
                                document.querySelector('.swal2-cancel').click()
                                break
                            case ENTER_KEY_CONFIRMAR:
                                document.querySelector('.swal2-confirm').click()
                                document.getElementById('CodigoBarrasOnfocus').dispatchEvent(new KeyboardEvent('keypress'))
                                break
                            case ENTER_KEY_GUARDAR_SALIDA:
                                document.getElementById('EnviarInformacion').click()
                                break
                            case ENTER_KEY_LISTA:
                                document.getElementById('productoSelect').click()
                                break
                            case ENTER_KEY_IMPORTE:
                                document.getElementById('ImportePagar').focus()
                                document.getElementById('ImportePagar').select()
                                break
                            case ENTER_KEY_CLIENTE:
                                break
                            case ENTER_KEY_AGREGAR_SOLICITANTE:
                                document.getElementById('InputSolicitante').focus()
                                break
                            case ENTER_KEY_AGREGAR_CLIENTE:
                                document.getElementById('AgregarCliente').click()
                                break
                            case ENTER_KEY_GUARDAR_CLIENTE:
                                document.getElementById('GuardarCliente').click()
                                break
                            default:
                                break
                        }
                    }
                })
			},

            habilitarOpciones () {
                this.isBlock = !this.isBlock
            },

            habilitarFacturacion () {
                this.isFactura = !this.isFactura
                if (this.isFactura) {
                    this.consulta.tiposalida_id = 2
                } else {
                    this.consulta.tiposalida_id = 1
                }
                this.$refs.tablacotizaciones.cambiarPrecioCarrito(this.consulta)
            },

            cargarAlmacenProductos () {
				this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
            },

            cargarUsuarios () {
				this.consulta.usuario = (this.user) ? this.user.usuario : Cookies.get(SISTEMA_USUARIO)
				if (this.$can('estimacion.cotizacion.por.usuarios')) {
					this.getUsuarioListar()
				}
            },

            cargarProductosMasivos () {
                this.getListaScrollMasivoProducto(this.consulta)
            },

            cargarTipoPrecioVenta () {
                this.getTipoPrecioVenta()
                .then(response => { 
                    this.consulta.tipoprecioventaID = '1'
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarTipoVenta () {
                this.getTipoVentas()
                .then(response => { 
                    this.consulta.tipo_venta_id = '1'
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarProveedor () {
                this.getProveedorListado()
                .then(response => { 
                    this.consulta.proveedor_id = Object.values(this.proveedores).filter(x => typeof x !== 'undefined').shift().proveedor_id
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarDatosCotizacion () {
                this.getDatosCotizacion(this.consulta)
                .then(response => { 
                    if (response.status === 200) {
                        if (this.estadomoduloventa === 'AGREGAR') {
							this.consulta.codigoVenta = response.data.venta.codigoVenta
						} else if (this.estadomoduloventa === 'EDITAR') {
							this.consulta.codigoVenta = this.updateventa.codigo
						}
					}
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },
            // Seleccion de Datos
            onChangeTipoPrecioVenta (value) {
				this.consulta.tipoprecioventaID = value
			},

            onChangeFechaVenta (date, dateString) {
				this.consulta.fechadeventa = dateString
			},

            onChangeUsuario (value) {
				this.consulta.usuario = value
            },

            onChangeAlmacen (value) {
				this.consulta.almacen_id = value
                this.cargarProductosMasivos()
                this.$refs.selectScrollProducto.verificarProductos()
            },

            onChangeDescuentoPorcentajeSeleccion (value) {
				this.consulta.descuentoPorcentajeManual = 0
				this.consulta.descuentoPorcentajeSeleccion = value.key
				this.consulta.descuentoPorcentajeNombre = value.label
			},

            onChangeDescuentoPorcentajeManual (value) {
                this.consulta.descuentoPorcentajeManual = value
            },

            onChangeDescuentoMoneda (value) {
                this.consulta.descuentoMonedaMonto = value
            },

            onChangeElegirCredito (value) {
				this.consulta.tipo_venta_id = value

				if (this.consulta.tipo_venta_id === '2') {
					this.habilitarcredito = true
				} else {
					this.habilitarcredito = false
				}
            },

            onChageScrollCliente (value) {
                this.consulta.clienteID = value.cliente_id
                this.consulta.clienteNombre = value.nombreFactura
                this.consulta.clienteNit = value.nitFactura
            },

            onDateCredito (date, dateString) {
                this.consulta.fechaCredito = dateString
            },

            onChangeFormaPago (value) {
				this.consulta.formapago = value
			},

			onChangeValidezPago (value) {
				this.consulta.validezcotizacion = value
			},

			onChangeTiempoEntrega (value) {
				this.consulta.tiempoentrega = value
			},
            // Resultado de Datos
            successProducto (value) {
                this.productoElegido = value
                this.$refs.tablacotizaciones.agregarProductoALaLista(value)
            },

            successProductoCodigo (value) {
                this.productoElegido = value
                this.$refs.tablacotizaciones.agregarProductoALaLista(value)
            },

            successSubtotal (value) {
                this.consulta.subtotal = value
            },

            successDescuento (value) {
                this.consulta.descuento = value
            },

            successTotal (value) {
                this.consulta.importePagar = utils.formatMoney(value, this.config.ventadecimal, '.', '')
                this.consulta.total = value
            },

            successTablaProducto (value) {
                this.consulta.productos = value
            },
            // Modales
            openVentanaNueva () {
				const ventana = parseInt(this.nuevaventanacotizacionid) + 1
				this.$emit('abrirNuevaVentanaCotizacion', ventana)
			},

            openModalCliente () {
                this.consulta.clienteNit = '0'
				this.consulta.clienteNombre = 'SIN NOMBRE'
				this.modalCliente = true
			},

            openModalBuscarProducto () {
				setTimeout(function () { 
                    this.modalBuscarProducto = true
                }.bind(this), 300)
			},

            closeModalBuscarProducto () {
                this.showModalIframeProducto = true
				this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosCotizacion()
                this.getListaScrollMasivoCliente()

				setTimeout(function () {
                    this.modalBuscarProducto = false
                    this.showModalIframeProducto = false
                }.bind(this), 500)
			},

            openModalProducto (data, tipo) {
                this.producto.producto = []
                this.producto.tipomodal = ''

                setTimeout(function () { 
                    this.producto.producto = data
                    this.producto.tipomodal = tipo
                    this.modalProducto = true
                }.bind(this), 300)
            },

            dataSuccessProducto (newValue) {
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosCotizacion()
                this.getListaScrollMasivoCliente()
                this.$refs.selectScrollProducto.verificarProductos()

                setTimeout(function () { 
                    this.modalProducto = false
                }.bind(this), 300)
            }, 
            // Operaciones
            buscarCliente (event) {
                const clienteNIT = this.consulta.clienteNit
                const result = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE)).filter(cliente => cliente.nitFactura === parseInt(clienteNIT)).shift()
                this.consulta.clienteNit = result.nitFactura
                this.consulta.clienteNombre = result.nombreFactura
            },

            realizarCotizacion () {
				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para cotizar y vuelva a intentar...', 'error')
                } else if (this.consulta.clienteNombre === '') {
                    Swal.fire('Atencion!', 'Asigne un Cliente Por Favor y vuelva a intentar...', 'error')
                } else {
                    Swal.fire({
                        title: 'Desea Cotizar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            this.consulta.cambio = this.calculoCambio
							
                            this.guardarCotizacion(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablacotizaciones.vaciarListaProductos()
                                    this.cargarDatosCotizacion()
                                    this.cargarProductosMasivos()
                                    this.getListaScrollMasivoCliente()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.clienteNit = 0
                                        this.consulta.clienteNombre = 'SIN NOMBRE'
                                        this.consulta.importePagar = 0

                                        const cotizacionID = parseInt(response.data.cotizacionID)
                                        if (cotizacionID) {
                                            if (response.data.imprimirVentaTermica === 'ACTIVADO') {
                                                utils.openReciboMobile(`/reporte/cotizacion/termica/${cotizacionID}`)
                                            } else {
                                                utils.openReciboMobile(`/reporte/cotizacion/reporte/carta/${cotizacionID}`)
                                            }
                                        }

                                        this.spinnerloading = false
                                    }.bind(this), 300)
                                }
                            })
                            // .catch(error => {
                            //     utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            // })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyCotizacion++
            },

            editarCotizacion () {
				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para cotizar y vuelva a intentar...', 'error')
                } else if (this.consulta.clienteNombre === '') {
                    Swal.fire('Atencion!', 'Asigne un Cliente Por Favor y vuelva a intentar...', 'error')
                } else {
                    Swal.fire({
                        title: 'Desea Cotizar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            this.consulta.cambio = this.calculoCambio

                            this.actualizarCotizacion(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablacotizaciones.vaciarListaProductos()
                                    this.cargarDatosCotizacion()
                                    this.cargarProductosMasivos()
                                    this.getListaScrollMasivoCliente()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.clienteNit = 0
                                        this.consulta.clienteNombre = 'SIN NOMBRE'
                                        this.consulta.importePagar = 0

                                        const cotizacionID = parseInt(response.data.cotizacionID)
                                        if (cotizacionID) {
                                            if (response.data.imprimirVentaTermica === 'ACTIVADO') {
                                                utils.openReciboMobile(`/reporte/cotizacion/termica/${cotizacionID}`)
                                            } else {
                                                utils.openReciboMobile(`/reporte/cotizacion/reporte/carta/${cotizacionID}`)
                                            }
                                        }

                                        this.$emit('successEditarCotizacion', 'Cotizacion Actualizada')
                                        this.spinnerloading = false
                                    }.bind(this), 300)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyEditarCotizacion++
            },

            eliminarListaProductos () {
                this.spinnerloading = true
                this.$refs.tablacotizaciones.vaciarListaProductos()
                this.cargarDatosCotizacion()
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.getListaScrollMasivoCliente()
                setTimeout(function () {        
                    this.spinnerloading = false
                }.bind(this), 500)
            }
        }
    }

</script>

<style>
    .codigodeventa {
        font-size:18px; 
        text-align:center; 
        background-color:#f3f3f3; 
        color: var(--element); 
        font-weight:700; 
        border-style: solid; 
        border-width: 1px;
        border-color: var(--element); 
        padding-top: 10px; 
        padding-bottom: 10px;
    }

    .border-importe {
        border-width: 1px;
    }
    .flex-card-left { max-width: 76%; }
    .flex-card-right { max-width: 24%; }
    .flex-card-codigo { max-width: 25%; }
    .flex-card-producto { max-width: 75%; }
    .flex-card-tabla { max-width: 100%; }

    @media screen and (min-width: 240px) and (max-width: 320px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 320px) and (max-width: 430px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 540px) and (max-width: 540px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 768px) and (max-width: 768px)  {
        .flex-card-left { max-width: 75%; }
        .flex-card-right { max-width: 25%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
    }
</style>
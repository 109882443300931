<template>
	<div class="progress col-md-12">
		<div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar" :style="{ width: getProgress }">
			{{ getProgress }}
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			'progress'
		],
		computed: {
			getProgress () {
				return Math.round(this.progress) + '%'
			}
		}
	}
</script>
<template>
	<div>
        <a-select
            size="large"
			show-search
			:value="valueScroll"
			:dropdownMatchSelectWidth="true"
			:default-active-first-option="false"
			:show-arrow="true"
			:filter-option="false"
			:not-found-content="spinnerScroll ? undefined : null"
			@search="handleBusquedaLista"
			@change="handleChangeLista"
			placeholder="Buscar Clientes por NIT, Nombre."
			style="width: 100%"
			id="productoSelect"
			>
			<a-spin v-if="spinnerScroll" slot="notFoundContent" size="large">
				<div class="spin-content">
					<i class="fas fa-search"></i> Obteniedo los Resultados ...
				</div>
			</a-spin>
			<a-select-option v-for="(option, i) in lista_scroll" :key="i" :value="option.cliente_id">
				{{ option.nitFactura }} {{ option.nombreFactura }}
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import { 
		STORAGE_LISTA_SCROLL_CLIENTE
	} from '@/utils/constants'

	export default {

		data () {
			return {
                utils,
                valueScroll: undefined,
                lista_scroll: [],
                spinnerScroll: false,
                loading: false
			}
		},

		mounted () {
            this.listaClientes()
        },

		methods: {

			listaClientes () {
				console.log('cargando interna de clientes para cotizacion y consultas')
				const listaclientes = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE))
				if (Object.is(listaclientes, null) === true) {
					this.listaClientes()
					// utils.openNotificationWithIcon('error', 'Atencion!!!', 'La lista de clientes esta vacio, vuelve a cargar por favor-', 'topRight')
				} else {
					// BUSCADOR AVANZADO INTERNO POR COINDIDENCIA //
					const listaclientes = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE))
					if (listaclientes.length > 150) {
						this.lista_scroll = []
						this.lista_scroll = listaclientes.slice(-100).reverse()
					} else {
						this.lista_scroll = listaclientes
					}
				}
			},

            async handleBusquedaLista (value) {
				if (value !== '') {
					if (value.length > 2) {
                        console.log('busqueda interna de clientes para cotizacion y consultas')
						const listaclientes = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE))
						if (Object.is(listaclientes, null) === true) {
							utils.openNotificationWithIcon('info', 'Atencion!!!', 'La lista de clientes esta vacio, vuelve a cargar por favor...', 'topRight')
						} else {
					    	// BUSCADOR AVANZADO INTERNO POR COINDIDENCIA //
					    	const listaclientes = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE))
					    	const resultado = listaclientes.filter(element => {
					    		const regex = new RegExp(value, 'giu')
					    		return element.detalle.match(regex)
					    	})
					    	if (resultado.length > 150) {
					    		this.lista_scroll = []
					    		this.lista_scroll = resultado.slice(-100).reverse()
					    	} else {
					    		this.lista_scroll = resultado
					    	}
					    }
					}
				}
			},

			handleChangeLista (value) {
				const clienteID = value

				const listacliente = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE))
				const result = listacliente.filter((cliente) => {
					return cliente.cliente_id === parseInt(clienteID)
				})

				const cliente = result.shift()
				this.valueScroll = `${cliente.nitFactura} ${cliente.nombreFactura}`
				this.$emit('successScrollCliente', cliente)
			}
			
		}

	}
</script>
<template>
    <div>
        <a-row type="flex" :gutter="[8,8]">
            <a-col class="flex-card-left" flex="1 1 75%">
                <a-row type="flex" :gutter="[2,2]">
                    <a-col flex="1 0 100%">
                        <a-button 
                            v-if="$can('transaccion.boton.opciones.de.venta')" 
                            size="small" 
                            @click="habilitarOpciones()"
                            >
                            <span v-if="!isBlock"><a-icon type="lock" /> Habilitar</span>
                            <span v-if="isBlock"><a-icon type="unlock" /> Deshabilitar</span>
                        </a-button>
                        <a-button 
                            v-if="$can('transaccion.busqueda.de.producto.en.ventas')" 
                            size="small" 
                            @click="openModalBuscarProducto()"
                            >
                            <a-icon type="search" /> Buscar Producto
                        </a-button>
                        <template v-if="estadomoduloventa === 'AGREGAR'">
                            <a-button 
                                size="small" 
                                type="primary" 
                                @click="openVentanaNueva()"
                                >
                                <a-icon type="desktop" /> Nueva Ventana {{ nuevaventanaventaid }}
                            </a-button>
                            
                            <a-button 
                                v-if="$can('transaccion.agregar.nuevo.producto.venta')"
                                size="small" 
                                type="primary" 
                                @click="openModalProducto(null, 'agregar')"
                                >
                                <a-icon type="plus" /> Nuevo Producto
                            </a-button>
                        </template>
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[2,2]">
                    <a-col flex="1 0 30%" v-if="$can('transaccion.venta.por.fechas')">
                        <span class="d-sm-block"><strong>Elige la fecha:</strong></span>
                        <a-date-picker 
                            :disabled="!isBlock" 
                            :value="utils.getDateFormat(consulta.fechadeventa)" 
                            @change="onChangeFechaVenta" 
                            :locale="locale"
                            size="large"
                            style="width: 100%"
                        />
                    </a-col>
                    
                    <a-col flex="1 0 20%" v-if="$can('transaccion.venta.por.usuarios')">
                        <!--SELECT USUARIO-->
                        <span class="d-sm-block"><strong>Usuario:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.usuario" 
                            @change="onChangeUsuario" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                        </a-select>
                        <!--SELECT USUARIO-->
                    </a-col>

                    <a-col flex="1 0 20%" v-if="$can('transaccion.venta.por.sucursales')">
                        <!--SELECT ALMACEN-->
                        <span class="d-sm-block"><strong>Sucursal:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.almacen_id" 
                            @change="onChangeAlmacen" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                        <!--SELECT ALMACEN-->
                    </a-col>

                    <a-col flex="1 0 20%">
                        <span class="d-sm-block"><strong>Tipo Precio:</strong></span>
                        <a-select 
                            :value="consulta.tipoprecioventaID" 
                            @change="onChangeTipoPrecioVenta" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in listatipoprecioventa" :key="index" :value="value.tipoprecioventa_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 20%" v-if="estadomoduloventa === 'AGREGAR'">
                        <span class="d-sm-block"><strong>Forma de Pago:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.tipo_venta_id" 
                            @change="onChangeElegirCredito"   
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in tipodeventas" :key="index" :value="value.plancuenta_id"> {{ value.nombre_corto }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 50%">
                        <span class="d-sm-block"><strong>Observaciones:</strong></span>
                        <a-textarea v-model="consulta.observaciones" placeholder="Observaciones de Venta" :rows="1" />
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[2,2]">
                    <a-col class="flex-card-codigo" flex="1 0 25%">
                        <center>
                            <label>CODIGO BARRAS: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F1)</span>
                            </label>
                        </center>
                        <SelectCodigoBarras
                            :almacenid="parseInt(consulta.almacen_id)"
                            :tipoprecioventaid="parseInt(consulta.tipoprecioventaID)"
                            :tiposalidaid="parseInt(consulta.tiposalida_id)"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successProductoStockCodigo="successProductoCodigo"
                        />      
                    </a-col>

                    <a-col class="flex-card-producto" flex="1 0 75%">
                        <center>
                            <label>SELECCIONA PRODUCTOS PARA LA VENTA: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F2)</span>
                            </label>
                            &nbsp;&nbsp; 
                            <a-button 
                                size="small"
                                :type="(!isFactura) ? 'dashed' : 'primary'"
                                @click="habilitarFacturacion()"
                                >
                                <span v-if="!isFactura"><a-icon type="file-done" /> Vendiendo</span>
                                <span v-if="isFactura"><a-icon type="file-done" /> Facturando</span>
                            </a-button>
                        </center>
                        <SelectScrollProductoStock
                            ref="selectScrollProductoStock" 
                            :almacenid="parseInt(consulta.almacen_id)"
                            :tipoprecioventaid="parseInt(consulta.tipoprecioventaID)"
                            :tiposalidaid="parseInt(consulta.tiposalida_id)"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successScrollProductoStock="successProducto"
                        />
                    </a-col>

                    <a-col class="flex-card-tabla" flex="1 1 100%">
                        <TablaProductos 
                            ref="tablaproductos"
                            :updateventa="updateventa"
                            :estadomoduloventa="estadomoduloventa"
                            :nuevaventanaventaid="nuevaventanaventaid"
                            :listaproducto="productoElegido"
                            @subtotal="successSubtotal"
                            @descuento="successDescuento"
                            @total="successTotal"
                            @productos="successTablaProducto"
                        />
                    </a-col>
                </a-row>
            </a-col>
            
            <a-col class="flex-card-right" flex="1 0 25%">
                <div style="height: 100px; background-color:#000000; overflow: auto;">
                    <center>
                        <label style="color:#fff;">Precio Total</label>
                        <p style="color:#0ed104; font-size:45px; font-weight:700;">{{ ViewSubtotal }}</p>
                    </center>
                </div>

                <template v-if="config.habilitardescuentomanualventa === 'DESACTIVADO'">
                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuento > 0">
                        <center>
                            <label style="color:#fff;">Precio Total con Descuento</label>
                            <p style="color:#ddf7ff; font-size:45px; font-weight:700;">{{ ViewSubtotalTotalConDescuento }}</p>
                        </center>
                    </div>
                </template>

                <template v-if="config.habilitardescuentomanualventa === 'ACTIVADO'">
                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuento > 0">
                        <center>
                            <label style="color:#fff;">Precio Total con Descuento</label>
                            <p style="color:#ddf7ff; font-size:45px; font-weight:700;">{{ ViewSubtotalTotalConDescuento }}</p>
                        </center>
                    </div>

                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuentoMonedaMonto > 0">
                        <center>
                            <label style="color:#fff;">Precio Total con Descuento</label>
                            <p style="color:#ddf7ff; font-size:45px; font-weight:700;">{{ ViewSubtotalTotalConDescuentoManual }}</p>
                        </center>
                    </div>
                </template>

                <h4 class="titulo-venta-color" style="margin-top: -1px;"><center>NRO DE VENTA</center></h4>
                <h2 class="codigodeventa">{{ consulta.codigoVenta }}</h2>

                <a-row v-if="habilitarcredito == false" :gutter="[8,8]">
                    <a-col :span="12">
                        <label class="d-sm-block">Importe: <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F3)</span></label>
                        <a-input 
                            v-model="consulta.importePagar"
                            @keypress="utils.onlyNumber($event)" 
                            id="ImportePagar" 
                            placeholder="0.00" 
                            class="border-importe"
                        />
                    </a-col>
                    <a-col :span="12" style="top: 2px;">
                        <label class="d-sm-block">Cambio: </label>
                        <div class="background-cambio" id="cambio">
                            <center>
                                <span v-if="ViewCalculoCambio !== 0.00">{{ ViewCalculoCambio }}</span>
                            </center>
                        </div>
                    </a-col>
                </a-row>

                <div class="row" v-if="habilitarcredito == true">
					<div class="col-md-12">
                        <center><label class="d-sm-block">FECHA COBRO DE CREDITO</label></center>
						<strong>
							<a-date-picker
								size="large"
								:value="utils.getDateFormat(consulta.fechaCredito)" 
								@change="onDateCredito" 
								:locale="locale" 
								style="width:100%; height:44px; font-size:14px;  border-width:2px; border-color:orange; border-style:solid;"
							/>
						</strong><br>
					</div>
				</div>

                <template v-if="config.habilitardescuentomanualventa === 'ACTIVADO'">
                    <div class="col-md-12" v-if="config.habilitardescuentoselectventa === 'ACTIVADO'">
						<label class="d-sm-block" style="margin-top: 3px;">Lista Porcentaje:</label>
						<a-select label-in-value :value="{ key: consulta.descuentoPorcentajeSeleccion }" @change="onChangeDescuentoPorcentajeSeleccion"  style="width: 100%">
							<a-select-option v-for="(value, index) in porcentajeventas" :key="index" :value="value.valor"> {{ value.nombre }}</a-select-option>
						</a-select>
					</div>

					<div class="col-md-12">
						<div class="form-group">
							<label class="d-sm-block" style="margin-top: 3px;">Descuento Total en {{ config.monedasimbolo }}</label>
							<a-input-number
								size="large"
								:max="1000000" 
								:value="consulta.descuentoMonedaMonto" 
								@change="onChangeDescuentoMoneda"
								onfocus="myFunction(this)" 
								placeholder="0.00"
								class="form-control form-control-lg border-importe"
							/>
						</div>
					</div>
				</template> 

                <br>

                <div class="input-group col-md-12 col-xs-12">
					<a-button block @click="openModalCliente()" id="AgregarCliente">
						<a-icon type="user" /> AGREGAR CLIENTE  (F6)
					</a-button>
				</div>

				<div style="background-color:#000000;">
					<center><font size="4" color="#0ed104"><strong><p>NIT : {{ consulta.clienteNit }}</p></strong></font></center>
					<center><font size="3" color="#0ed104"><strong><p>{{ consulta.clienteNombre }}</p></strong></font></center>
				</div>

                <template v-if="estadomoduloventa === 'AGREGAR'">
					<div class="box" v-if="habilitarcredito == false">
						<button 
                            type="button" 
                            id="EnviarInformacion" 
                            class="btn btn-block btn-lg" 
                            style="background-color:var(--element);" 
                            @click.once="realizarPago()" 
                            :key="buttonKeyPago"
                            :disabled="estadoarqueocaja === 'CerrarCaja' || estadoarqueocaja === 'AbrirCaja'"
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white;">PAGAR AHORA <span style="font-size:13px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
					</div>

					<div class="box" v-if="habilitarcredito == true">
						<button 
                            type="button" 
                            id="EnviarInformacion" 
                            class="btn btn-block btn-lg" 
                            style="background-color:var(--element);"  
                            @click.once="realizarPagoCredito()"
                            :disabled="estadoarqueocaja === 'CerrarCaja'"
                            :key="buttonKeyCredito" 
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white;">REALIZAR CREDITO <span style="font-size:13px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
					</div>
                </template>

                <template v-if="estadomoduloventa === 'EDITAR'">
                    <div class="box" v-if="habilitarcredito == false">
                        <button 
                            type="button" 
                            id="EnviarInformacion" 
                            class="btn btn-block btn-lg" 
                            style="background-color:var(--element);"  
                            @click.once="editarPago()" 
                            :disabled="estadoarqueocaja === 'CerrarCaja'"
                            :key="buttonKeyEditarVenta"
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white;">EDITAR VENTA <span style="font-size:13px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
                    </div>
                </template>

                <div class="box">
					<button 
                        type="button"
                        id="EnviarInformacion" 
                        class="btn btn-block btn-lg" 
                        style="background-color:#ff3333;" 
                        @click="eliminarListaProductos()" 
                        >
                        <font size=2>
                            <center>
                                <strong style="color:white;">Vaciar lista de items</strong>
                            </center>
                        </font>
                    </button>
                    <br>
                    <center>
                        <a href="#" @click.prevent="actualizarListaProductos()"><span style="font-weight:700; color:var(--element);"><a-icon type="sync" /> Cargar Lista de Productos</span></a>
                    </center>
				</div>
            </a-col>
        </a-row>

        <!--PRODUCTO PROVEEDOR-->
		<a-modal
            v-model="modalBuscarProducto"
            :title="false"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1260"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <ModuloProductoProveedor 
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :statecodigobarradefault="config.statecodigobarradefault" 
                    :disabledvencimiento="config.disabledvencimiento" 
                    :preciocifradecimal="config.preciocifradecimal" 
                    :preciocifraentera="config.preciocifraentera" 
                    :precioreglatres="config.precioreglatres"  
                    :sessionuserid="config.userid"
                    :monedasimbolo="config.monedasimbolo"
                    :almacenid="config.almacenid"			
                /> 
            </div>

            <template slot="footer">
                <a-button key="back" @click="closeModalBuscarProducto()">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTO PROVEEDOR-->

        <!--CLIENTE-->
		<a-modal
            v-model="modalCliente"
            title="GESTION CLIENTES"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <div style="background-color:#000000;">
					<center><font size="7" color="#0ed104"><strong><p>NIT : {{ consulta.clienteNit }}</p></strong></font></center>
					<center><font size="6" color="#0ed104"><strong><p>{{ consulta.clienteNombre }}</p></strong></font></center>
				</div>
				<div class="form-group col-md-12 col-xs-12">
					<label>Buscar Cliente  <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F4)</span></label>
					<SelectScrollCliente 
                        @successScrollCliente="onChageScrollCliente"
                    />
				</div>
				<div class="form-group col-md-12 col-xs-12">
					<label>NIT /CI: <span style="font-weight:700; font-size:12px; color: rgb(32, 80, 129);">Presione ENTER para consultar</span></label>
					<a-input 
						size="large"
						v-model="consulta.clienteNit" 
						@keypress.enter="buscarCliente($event)" 
						placeholder="5744678011" 
						autofocus
					/>
				</div>
				<div class="form-group col-md-12 col-xs-12">
					<label>Nombre del Cliente o Razón Social: </label>
					<a-input 
						size="large"
						v-model="consulta.clienteNombre" 
						placeholder="Nombre Cliente"
					/>
				</div>
            </div>

            <template slot="footer">
				<a-button 
                    type="danger" 
                    id="CerrarModalCliente" 
                    ref="CerrarModalCliente" 
                    size="large"
                    @click="modalCliente = false"
                    >
                    <i class="fa fa-ban"></i> CERRAR (ESC)
                </a-button>
				
                <a-button 
                    type="primary" 
                    size="large"
                    @click="modalCliente = false"
                    id="GuardarCliente"
                    > 
                    <i class="fa fa-check"></i> REGISTRAR CLIENTE (F8)
                </a-button>
            </template>
        </a-modal>
        <!--CLIENTE-->

        <!--PRODUCTOS-->
		<a-modal
            v-model="modalProducto"
            title="GESTION DE PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteProducto
                    :tipoproducto="producto.tipomodal"
                    :dataproducto="producto.producto"
                    :almacenid="parseInt(consulta.almacen_id)"
                    :proveedorid="consulta.proveedor_id"
                    :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
                    @successProducto="dataSuccessProducto"
                />
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalProducto = false">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTOS-->

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
	import moment from 'moment'
    import Swal from 'sweetalert2'
	import * as utils from '@/utils/utils'
    import Cookies from 'js-cookie'
    import { mapGetters, mapActions } from 'vuex'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import 'moment/locale/es'
    import SelectScrollProductoStock from '../../../components/SelectScrollProductoStock.vue'
    import SelectCodigoBarras from '../../../components/SelectCodigoBarras.vue'
    import TablaProductos from '../../../components/TablaProductos.vue'
    import SelectScrollCliente from '../../../components/SelectScrollCliente.vue'
    import ComponenteProducto from '../../Inventario/Components/ComponenteProducto.vue'
	import ModuloProductoProveedor from '../../Inventario/Modulos/ModuloProductoProveedor.vue'
    import * as database from '@/utils/database'

    import {
        ALMACEN_SISTEMA_KEY_ID,
        SISTEMA_USUARIO,
        STORAGE_LISTA_SCROLL_CLIENTE
    } from '@/utils/constants'

    export default {

        props: [
            'nuevaventanaventaid', 
            'estadomoduloventa',
            'updateventa',
            'estadoarqueocaja'
        ],

        components: {
            SelectScrollProductoStock,
            SelectCodigoBarras,
            TablaProductos,
            SelectScrollCliente,
            ModuloProductoProveedor,
            ComponenteProducto
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                utils,
                productoElegido: {},
                consulta: {
                    venta_id: '',
                    almacen_id: '',
                    proveedor_id: '',
                    tipoprecioventaID: '',
                    usuario: 'Seleccionar',
                    tiposalida_id: 1,
                    tipo_venta_id: '',
                    observaciones: '',
                    fechaCredito: '',
                    fechadeventa: '',
                    productos: [],
                    subtotal: '',
                    descuento: '',
                    descuentoTotal: '',
                    total: '',
                    totalAPagar: '',
                    descuentoPorcentajeManual: 'Seleccionar Descuento',
                    descuentoPorcentajeSeleccion: '',
                    descuentoPorcentajeNombre: '',
                    descuentoMonedaMonto: 0,
                    clienteID: '',
                    clienteNombre: 'SIN NOMBRE',
                    clienteNit: '0',
                    codigoVenta: '',
                    importePagar: '',
                    cambio: ''
                },
                producto: {
                    producto: {},
                    tipomodal: ''
                },
                isBlock: false,
                isFactura: false,
                spinnerloading: false,
                habilitardescuentomanualventa: false,
                habilitarcredito: false,
                modalCliente: false,
                modalBuscarProducto: false,
                modalProducto: false,
                buttonKeyPago: 100,
                buttonKeyCredito: 200,
                buttonKeyCliente: 300,
                buttonKeyEditarVenta: 400
            }
        },

        mounted () {
            this.getAlmacenListar()
            this.cargarAlmacenProductos()
            this.cargarUsuarios()
            this.cargarTipoPrecioVenta()
            this.cargarTipoVenta()
            this.cargarDatosVenta()
            this.cargarProveedor()
            this.getPorcentajeVenta()
            this.getListaScrollMasivoCliente()
            this.comandkeys()

            if (this.estadomoduloventa === 'EDITAR') {
				setTimeout(function () {
                    this.consulta.venta_id = this.updateventa.venta_id
                    this.consulta.almacen_id = this.updateventa.almacenID.toString()
                    this.consulta.tiposalida_id = this.updateventa.tiposalidaID.toString()
                    this.consulta.tipo_venta_id = this.updateventa.plancuentaID.toString()
                    this.consulta.fechadeventa = (this.updateventa.fechaVenta) ? this.updateventa.fechaVenta : ''
                    this.consulta.clienteID = this.updateventa.clienteID
                    this.consulta.clienteNombre = this.updateventa.nombreFactura
                    this.consulta.clienteNit = this.updateventa.nitFactura
                    this.consulta.observaciones = this.updateventa.observacion
                    this.consulta.codigoVenta = this.updateventa.codigoVenta
                }.bind(this), 500)
			}
        },

        computed: {
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('tipoprecioventa', ['listatipoprecioventa']),
            ...mapGetters('plancuenta', ['tipodeventas']),
            ...mapGetters('venta', ['porcentajeventas']),
            ...mapGetters('proveedor', ['proveedores']),

            calculoCambio () {
                const importeTotal = parseFloat(this.consulta.importePagar)
                const subtotal = this.consulta.total
                return (!isNaN(importeTotal) && !isNaN(subtotal) && importeTotal > 0) ? Math.max(0, importeTotal - subtotal) : 0
            },

            descuentoTotal () {
                return (this.consulta.descuentoMonedaMonto > 0) ? this.consulta.descuento + this.consulta.descuentoMonedaMonto : this.consulta.descuento
            },

            total () {
                return (this.consulta.descuentoMonedaMonto > 0) ? this.consulta.total - this.consulta.descuentoMonedaMonto : this.consulta.total
            },

            ViewSubtotal () {
                return utils.formatMoney(this.consulta.subtotal, this.config.ventadecimal, '.', '')
            },

            ViewSubtotalTotalConDescuento () {
                return utils.formatMoney(this.consulta.total, this.config.ventadecimal, '.', '')
            },

            ViewSubtotalTotalConDescuentoManual () {
                return utils.formatMoney(this.total, this.config.ventadecimal, '.', '')
            },

            ViewCalculoCambio () {
                return utils.formatMoney(this.calculoCambio, this.config.ventadecimal, '.', '')
            }
        },

        methods: {
            ...mapActions('almacen', ['getAlmacenListar']),
            ...mapActions('usuario', ['getUsuarioListar']),
            ...mapActions('tipoprecioventa', ['getTipoPrecioVenta']),
            ...mapActions('plancuenta', ['getTipoVentas']),
            ...mapActions('venta', ['getDatosVenta', 'getPorcentajeVenta', 'guardarVenta', 'guardarVentaCredito', 'editarVenta']),
            ...mapActions('cliente', ['getListaScrollMasivoCliente']),
            ...mapActions('proveedor', ['getProveedorListado']),
            ...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),

            comandkeys () {
				var self = this

				var ENTER_KEY_CLOSE = 27
				var ENTER_KEY_SUB_CLOSE = 27
				var ENTER_KEY_CONFIRMAR = 13

				var ENTER_KEY_CODIGO_BARRA = 112
				var ENTER_KEY_LISTA = 113
				var ENTER_KEY_IMPORTE = 114
				var ENTER_KEY_CLIENTE = 115
				var ENTER_KEY_TIPO_SALIDA = 116
				var ENTER_KEY_AGREGAR_CLIENTE = 117
				var ENTER_KEY_AGREGAR_SOLICITANTE = 118
				var ENTER_KEY_GUARDAR_CLIENTE = 119
				var ENTER_KEY_GUARDAR_SALIDA = 120
				var ENTER_KEY_MAYUS = 16
				var ENTER_KEY_O = 79
				var ENTER_KEY_J = 74
				var ENTER_KEY_CTRL = 17
				var ENTER_KEY_PLUS = 61

				document.addEventListener('keydown', function (e) {
                    const code = e.keyCode || e.which
                    const habilitarControlCodigoBarras = self.config.habilitarcontrolcodigobarras === 'ACTIVADO'
                    const codesToPrevent = [74, 61, 17, 27, 112, 113, 114, 115, 116, 117, 118, 119, 120]

                    if ((habilitarControlCodigoBarras && codesToPrevent.includes(code)) || (!habilitarControlCodigoBarras && codesToPrevent.slice(1).includes(code))) {
                        e.preventDefault()
                        
                        switch (code) {
                            case ENTER_KEY_TIPO_SALIDA:
                                console.log('F5')
                                break
                            case ENTER_KEY_J:
                            case ENTER_KEY_PLUS:
                            case ENTER_KEY_CTRL:
                            case ENTER_KEY_CODIGO_BARRA:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                break
                            case ENTER_KEY_CLOSE:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                document.getElementById('CerrarModalCliente').click()
                                break
                            case ENTER_KEY_SUB_CLOSE:
                                document.querySelector('.swal2-cancel').click()
                                break
                            case ENTER_KEY_CONFIRMAR:
                                document.querySelector('.swal2-confirm').click()
                                document.getElementById('CodigoBarrasOnfocus').dispatchEvent(new KeyboardEvent('keypress'))
                                break
                            case ENTER_KEY_GUARDAR_SALIDA:
                                document.getElementById('EnviarInformacion').click()
                                break
                            case ENTER_KEY_LISTA:
                                document.getElementById('productoSelect').click()
                                break
                            case ENTER_KEY_IMPORTE:
                                document.getElementById('ImportePagar').focus()
                                document.getElementById('ImportePagar').select()
                                break
                            case ENTER_KEY_CLIENTE:
                                break
                            case ENTER_KEY_AGREGAR_SOLICITANTE:
                                document.getElementById('InputSolicitante').focus()
                                break
                            case ENTER_KEY_AGREGAR_CLIENTE:
                                document.getElementById('AgregarCliente').click()
                                break
                            case ENTER_KEY_GUARDAR_CLIENTE:
                                document.getElementById('GuardarCliente').click()
                                break
                            default:
                                break
                        }
                    }
                })
			},

            habilitarOpciones () {
                this.isBlock = !this.isBlock
            },

            habilitarFacturacion () {
                this.isFactura = !this.isFactura
                if (this.isFactura) {
                    this.consulta.tiposalida_id = 2
                } else {
                    this.consulta.tiposalida_id = 1
                }
                this.$refs.tablaproductos.cambiarPrecioCarrito(this.consulta)
            },

            cargarAlmacenProductos () {
				this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
            },

            cargarUsuarios () {
				this.consulta.usuario = (this.user) ? this.user.usuario : Cookies.get(SISTEMA_USUARIO)
				if (this.$can('transaccion.venta.por.usuarios')) {
					this.getUsuarioListar()
				}
            },

            cargarProductosMasivos () {
                this.getListaScrollMasivoProductoStock(this.consulta)
            },

            cargarTipoPrecioVenta () {
                this.getTipoPrecioVenta()
                .then(response => { 
                    this.consulta.tipoprecioventaID = '1'
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarTipoVenta () {
                this.getTipoVentas()
                .then(response => { 
                    this.consulta.tipo_venta_id = '1'
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarProveedor () {
                this.getProveedorListado()
                .then(response => { 
                    this.consulta.proveedor_id = Object.values(this.proveedores).filter(x => typeof x !== 'undefined').shift().proveedor_id
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarDatosVenta () {
                this.getDatosVenta(this.consulta)
                .then(response => { 
                    if (response.status === 200) {
						if (this.estadomoduloventa === 'AGREGAR') {
							this.consulta.codigoVenta = response.data.venta.codigoVenta
						} else if (this.estadomoduloventa === 'EDITAR') {
							this.consulta.codigoVenta = this.updateventa.codigoVenta
						}
					}
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },
            // Seleccion de Datos
            onChangeTipoPrecioVenta (value) {
				this.consulta.tipoprecioventaID = value
			},

            onChangeFechaVenta (date, dateString) {
				this.consulta.fechadeventa = dateString
			},

            onChangeUsuario (value) {
				this.consulta.usuario = value
            },

            onChangeAlmacen (value) {
				this.consulta.almacen_id = value
                this.cargarProductosMasivos()
                this.$refs.selectScrollProductoStock.verificarProductos()
            },

            onChangeDescuentoPorcentajeSeleccion (value) {
				this.consulta.descuentoPorcentajeManual = 0
				this.consulta.descuentoPorcentajeSeleccion = value.key
				this.consulta.descuentoPorcentajeNombre = value.label
			},

            onChangeDescuentoPorcentajeManual (value) {
                this.consulta.descuentoPorcentajeManual = value
            },

            onChangeDescuentoMoneda (value) {
                this.consulta.descuentoMonedaMonto = typeof value === 'string' ? 0 : value
            },

            onChangeElegirCredito (value) {
				this.consulta.tipo_venta_id = value

				if (this.consulta.tipo_venta_id === '2') {
					this.habilitarcredito = true
				} else {
					this.habilitarcredito = false
				}
            },

            onChageScrollCliente (value) {
                this.consulta.clienteID = value.cliente_id
                this.consulta.clienteNombre = value.nombreFactura
                this.consulta.clienteNit = value.nitFactura
            },

            onDateCredito (date, dateString) {
                this.consulta.fechaCredito = dateString
            },
            // Resultado de Datos
            successProducto (value) {
                this.productoElegido = value
                this.$refs.tablaproductos.agregarProductoALaLista(value)
            },

            successProductoCodigo (value) {
                this.productoElegido = value
                this.$refs.tablaproductos.agregarProductoALaLista(value)
            },

            successSubtotal (value) {
                this.consulta.subtotal = value
            },

            successDescuento (value) {
                this.consulta.descuento = value
            },

            successTotal (value) {
                this.consulta.importePagar = utils.formatMoney(value, this.config.ventadecimal, '.', '')
                this.consulta.total = value
            },

            successTablaProducto (value) {
                this.consulta.productos = value
            },
            // Modales
            openVentanaNueva () {
				const ventana = parseInt(this.nuevaventanaventaid) + 1
				this.$emit('abrirNuevaVentana', ventana)
			},

            openModalCliente () {
                this.consulta.clienteNit = '0'
				this.consulta.clienteNombre = 'SIN NOMBRE'
				this.modalCliente = true
			},

            openModalBuscarProducto () {
				setTimeout(function () { 
                    this.modalBuscarProducto = true
                }.bind(this), 300)
			},

            closeModalBuscarProducto () {
                this.showModalIframeProducto = true
				this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosVenta()
                this.getListaScrollMasivoCliente()

				setTimeout(function () {
                    this.modalBuscarProducto = false
                    this.showModalIframeProducto = false
                }.bind(this), 500)
			},

            openModalProducto (data, tipo) {
                this.producto.producto = []
                this.producto.tipomodal = ''

                setTimeout(function () { 
                    this.producto.producto = data
                    this.producto.tipomodal = tipo
                    this.modalProducto = true
                }.bind(this), 300)
            },

            dataSuccessProducto (newValue) {
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosVenta()
                this.getListaScrollMasivoCliente()
                this.$refs.selectScrollProductoStock.verificarProductos() 

                setTimeout(function () { 
                    this.modalProducto = false
                }.bind(this), 300)
            }, 
            // Operaciones
            buscarCliente (event) {
                const clienteNIT = this.consulta.clienteNit
                const result = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_CLIENTE)).filter(cliente => cliente.nitFactura === parseInt(clienteNIT)).shift()
                this.consulta.clienteNit = result.nitFactura
                this.consulta.clienteNombre = result.nombreFactura
            },

            realizarPago () {
                const importeIngresado = parseFloat(this.consulta.importePagar)
                const totalAVender = parseFloat(this.total)

				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para vender y vuelva a intentar...', 'error')
                } else if (this.consulta.importePagar === 0) {
                    Swal.fire('Atencion!', 'Introduzca un monto a Pagar y vuelva a intentar...', 'error')
                } else if (this.consulta.clienteNombre === '') {
                    Swal.fire('Atencion!', 'Asigne un Cliente Por Favor y vuelva a intentar...', 'error')
                } else if (totalAVender < 0 || importeIngresado < 0) {
                    Swal.fire('Atencion!', 'Los valores no deben ser negativos...', 'error')
                } else if (totalAVender <= 0) {
                    Swal.fire('Atencion!', 'El precio total de venta debe ser mayor a Cero, vuelva a intentar...', 'error')
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            this.consulta.cambio = this.calculoCambio
                            this.consulta.descuentoTotal = this.descuentoTotal
                            this.consulta.totalAPagar = this.total

                            this.guardarVenta(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablaproductos.vaciarListaProductos()
                                    this.cargarDatosVenta()
                                    this.cargarProductosMasivos()
                                    this.getListaScrollMasivoCliente()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.clienteNit = 0
                                        this.consulta.clienteNombre = 'SIN NOMBRE'
                                        this.consulta.importePagar = 0

                                        const ventaID = parseInt(response.data.ventaID)
                                        if (ventaID) {
                                            if (response.data.imprimirVentaTermica === 'ACTIVADO') {
                                                if (response.data.imprimirBoletaCobro === 'ACTIVADO') {
                                                    // IMPRESION DE VENTA TERMINA +
                                                    // IMPRESION CON BOLETA DE COBRO
                                                } else {
                                                    utils.openReciboMobile(`/reporte/venta/termica/${ventaID}`)
                                                }
                                            } else {
                                                if (response.data.imprimirBoletaCobro === 'ACTIVADO') {
                                                    
                                                } else {
                                                    utils.openReciboMobile(`/reporte/venta/reporte/carta/${ventaID}`)
                                                }
                                            }
                                        }

                                        this.spinnerloading = false
                                        this.consulta.descuentoMonedaMonto = 0
                                    }.bind(this), 300)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyPago++
            },

            realizarPagoCredito () {
				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para vender y vuelva a intentar...', 'error')
                } else if (this.consulta.clienteNombre === '') {
                    Swal.fire('Atencion!', 'Asigne un Cliente Por Favor y vuelva a intentar...', 'error')
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            this.consulta.cambio = this.calculoCambio
                            this.consulta.descuentoTotal = this.descuentoTotal
                            this.consulta.totalAPagar = this.total
							
                            this.guardarVentaCredito(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablaproductos.vaciarListaProductos()
                                    this.cargarDatosVenta()
                                    this.cargarProductosMasivos()
                                    this.getListaScrollMasivoCliente()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.clienteNit = 0
                                        this.consulta.clienteNombre = 'SIN NOMBRE'
                                        this.consulta.importePagar = 0

                                        const ventaID = parseInt(response.data.ventaID)
                                        if (ventaID) {
                                            if (response.data.imprimirVentaTermica === 'ACTIVADO') {
                                                if (response.data.imprimirBoletaCobro === 'ACTIVADO') {
                                                    // IMPRESION DE VENTA TERMINA +
                                                    // IMPRESION CON BOLETA DE COBRO
                                                } else {
                                                    utils.openReciboMobile(`/reporte/venta/termica/${ventaID}`)
                                                }
                                            } else {
                                                if (response.data.imprimirBoletaCobro === 'ACTIVADO') {
                                                    
                                                } else {
                                                    utils.openReciboMobile(`/reporte/venta/reporte/carta/${ventaID}`)
                                                }
                                            }
                                        }

                                        this.spinnerloading = false
                                        this.consulta.descuentoMonedaMonto = 0
                                    }.bind(this), 300)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyCredito++
            },

            editarPago () {
                const importeIngresado = parseFloat(this.consulta.importePagar)
                const totalAVender = parseFloat(this.total)

				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para vender y vuelva a intentar...', 'error')
                } else if (this.consulta.importePagar === 0) {
                    Swal.fire('Atencion!', 'Introduzca un monto a Pagar y vuelva a intentar...', 'error')
                } else if (this.consulta.clienteNombre === '') {
                    Swal.fire('Atencion!', 'Asigne un Cliente Por Favor y vuelva a intentar...', 'error')
                } else if (totalAVender < 0 || importeIngresado < 0) {
                    Swal.fire('Atencion!', 'Los valores no deben ser negativos...', 'error')
                } else if (totalAVender <= 0) {
                    Swal.fire('Atencion!', 'El precio total de venta debe ser mayor a Cero, vuelva a intentar...', 'error')
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
                            this.consulta.cambio = this.calculoCambio
                            this.consulta.descuentoTotal = this.descuentoTotal
                            this.consulta.totalAPagar = this.total
							
                            this.editarVenta(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablaproductos.vaciarListaProductos()
                                    this.cargarDatosVenta()
                                    this.cargarProductosMasivos()
                                    this.getListaScrollMasivoCliente()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.clienteNit = 0
                                        this.consulta.clienteNombre = 'SIN NOMBRE'
                                        this.consulta.importePagar = 0

                                        const ventaID = parseInt(response.data.ventaID)
                                        if (ventaID) {
                                            if (response.data.imprimirVentaTermica === 'ACTIVADO') {
                                                if (response.data.imprimirBoletaCobro === 'ACTIVADO') {
                                                    // IMPRESION DE VENTA TERMINA +
                                                    // IMPRESION CON BOLETA DE COBRO
                                                } else {
                                                    utils.openReciboMobile(`/reporte/venta/termica/${ventaID}`)
                                                }
                                            } else {
                                                if (response.data.imprimirBoletaCobro === 'ACTIVADO') {
                                                    
                                                } else {
                                                    utils.openReciboMobile(`/reporte/venta/reporte/carta/${ventaID}`)
                                                }
                                            }
                                        }

                                        this.spinnerloading = false
                                        this.consulta.descuentoMonedaMonto = 0
                                        this.$emit('successEditarVenta', 'Venta Actualizada')
                                    }.bind(this), 300)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
                this.buttonKeyEditarVenta++
            },

            eliminarListaProductos () {
                this.spinnerloading = true
                this.$refs.tablaproductos.vaciarListaProductos()
                this.cargarDatosVenta()
                this.cargarProductosMasivos()
                this.getListaScrollMasivoCliente()
                setTimeout(function () {        
                    this.spinnerloading = false
                }.bind(this), 500)
            },

            actualizarListaProductos () {
                database.deleteDatabase()
    		    .then(() => console.log('Base de datos eliminada con éxito'))
    		    .catch((error) => console.error('Error al eliminar la base de datos:', error))

                this.spinnerloading = true
                this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
                this.getListaScrollMasivoProducto(this.consulta)
                this.getListaScrollMasivoProductoStock(this.consulta)
                setTimeout(function () {        
                    this.spinnerloading = false
                }.bind(this), 5000)
            }
        }
    }

</script>

<style>
    .codigodeventa {
        font-size:18px; 
        text-align:center; 
        background-color:#f3f3f3; 
        color: var(--element); 
        font-weight:700; 
        border-style: solid; 
        border-width: 1px;
        border-color: var(--element); 
        padding-top: 10px; 
        padding-bottom: 10px;
    }

    .border-importe {
        border-width: 1px;
    }

    .flex-card-left { max-width: 76%; }
    .flex-card-right { max-width: 24%; }
    .flex-card-codigo { max-width: 25%; }
    .flex-card-producto { max-width: 75%; }
    .flex-card-tabla { max-width: 100%; }

    @media screen and (min-width: 240px) and (max-width: 320px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 320px) and (max-width: 430px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 540px) and (max-width: 540px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 768px) and (max-width: 768px)  {
        .flex-card-left { max-width: 75%; }
        .flex-card-right { max-width: 25%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
    }
</style>
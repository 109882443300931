<template>
    <div v-if="$can('estimacion.menu.nueva.cotizacion')">
        <NuevaCotizacion
            :nuevaventanacotizacionid="parseInt(this.ventanacotizacionID)"
            :estadomoduloventa="`AGREGAR`"
            :updateventa="[]"
            @abrirNuevaVentanaCotizacion="openNuevaVentana"
        />
    </div>
</template>

<script>
    import NuevaCotizacion from './Modulos/NuevaCotizacion.vue'

    export default {

        props: [
            'ventanacotizacionID'
        ],

        components: {
            NuevaCotizacion
        },

        methods: {
            openNuevaVentana (value) {
                const ruta = this.$router.resolve({
					name: 'nuevacotizacion',
					params: { ventanacotizacionID: value }
				})

                window.open(ruta.href, '_blank')
            }
        }
    }
</script>
<template>
	<div>
        <a-select
            size="large"
			show-search
			:dropdownMatchSelectWidth="true"
			:value="valueProductoScroll"
			:default-active-first-option="false"
			:show-arrow="true"
			:filter-option="false"
			:not-found-content="spinnerScroll ? undefined : null"
			@search="handleBusquedaListaProductos"
			@change="handleChangeListaProductos"
			placeholder="Buscar Productos por Codigo, Nombre, Medida, Marca."
			style="width: 100%;"
			id="productoSelect"
			>
			<a-spin v-if="spinnerScroll" slot="notFoundContent" size="large">
				<div class="spin-content">
					<i class="fas fa-search"></i> Obteniedo los Resultados ...
				</div>
			</a-spin>

			<template v-if="config.istotallistaproductoventa == 'ACTIVADO'">
				<a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
					{{ option.nombre }}
				</a-select-option>
			</template>

			<template v-if="config.istotallistaproductoventa == 'DESACTIVADO'">
				<template v-if="config.ismarcaizquierdalistaventa == 'ACTIVADO'">
					<a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
						{{ option.codigo }} {{ option.marca }} {{ option.nombre }} {{ option.medida }} {{ option.unidad }}
					</a-select-option>
				</template>

				<template v-if="config.ismarcaizquierdalistaventa == 'DESACTIVADO'">
					<a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
						{{ option.codigo }} {{ option.nombre }} {{ option.medida }} {{ option.unidad }} {{ option.marca }}
					</a-select-option>
				</template>
			</template>
		</a-select>

		<a-modal
			v-model="isModalNotificacion"
			:title="false"
            :footer="false"
			>
			<center><h3>Aviso de Sistema</h3></center>
			<a-card>
				<a-row :gutter="[20, 10]" v-if="tipoNotificacion === 'productoNoEncontrado'">
					<a-col :span="12">
						<center><img :src="imageEncontradoBase" class="card"/></center>
					</a-col>
					<a-col :span="12">
						<center><img :src="imageEncontrado" class="card"/></center><br>	
					</a-col>
				</a-row>

				<template slot="actions" class="ant-card-actions">
					<template v-if="tipoNotificacion === 'precioVentaNoEncontrado'">
						<a-button
							v-if="$can('existencia.menu.inventario')"
							type="primary" 
							class="hidden-app-x ant-btn-header v-step-escritorio-3" 
							style="margin-left: 2px;" 
							@click="openMenu('inventario')"
							>
							<a-icon type="file-protect" :style="{ fontSize: '20px', fontWeight: '700' }"/> Ingresar a Inventarios
						</a-button>
					</template>
				</template>

				<a-card-meta title="Alerta producto no encontrado" v-if="tipoNotificacion === 'productoNoEncontrado'">
					<span slot="description">
						<a-alert 
							message="Posibles causas del conflicto:"
							type="info"
							banner
							closable
							>
							<span slot="description">
								<ul>
									<li>Tal vez <span class="note-title">no existe</span> el producto seleccionado en la sucursal.</li>
									<li>Tal vez el producto <span class="note-title">no tiene stock </span>actual.</li>
									<li>Tal vez el Producto <span class="note-title">no se ha cargado </span>correctamente por la velocidad de internet.</li>
								</ul>
							</span>
						</a-alert>
						<p style="color:#323232;">
							<span class="note-title">NOTA: </span> Para solucionar puede hacer clic en "Cargar Lista de Productos" o volver a cargar nuevamente el sistema.
						</p>
					</span>
				</a-card-meta>

				<a-card-meta title="Precio de venta no encontrado" v-if="tipoNotificacion === 'precioVentaNoEncontrado'">
					<span slot="description">
						<a-alert 
							message="Posibles causas:"
							type="warning"
							banner
							closable
							>
							<span slot="description">
								<ul>
									<li>El producto <span class="note-title">no tiene precio de venta.</span> Por favor debe agregar Precios de Venta al Producto segun la Sucursal donde pertenezca.</li>
								</ul>
							</span>
						</a-alert>

						<p style="color:#323232;">
							<br>
							<span class="note-title">NOTA: </span> Para solucionar puede hacer clic en "Ingresar a Inventarios".
						</p>
					</span>
				</a-card-meta>
			</a-card>
		</a-modal>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import router from '@/config/router'
	import { mapGetters, mapActions } from 'vuex'
	import * as database from '@/utils/database'
    import { 
		STORAGE_LISTA_SCROLL_PRODUCTO_STOCK
	} from '@/utils/constants'

	function escapeRegExp (string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
	}

	export default {

        props: {
			almacenid: { type: Number, required: true },
			tipoprecioventaid: { type: Number, required: true },
			tiposalidaid: { type: Number, required: true },
			tipocambioid: { type: Number, required: true },
			listacambio: { type: Array, required: true }
		},

		data () {
			return {
                utils,
				imageEncontrado: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/ProductoNoEncontrado.gif' : '/static/assets/images/sistema/ProductoNoEncontrado.gif',
				imageEncontradoBase: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/sistema/ProductoNoEncontradoBase.gif' : '/static/assets/images/sistema/ProductoNoEncontradoBase.gif',
				spinnerloading: false,
                valueProductoScroll: undefined,
                lista_producto_scroll: [],
                spinnerScroll: false,
                loading: false,
				isModalNotificacion: false,
				tipoNotificacion: ''
			}
		},

		mounted () {
			this.preCargaDeProductos()
        },

		computed: {
            ...mapGetters('configuracion', ['config'])
        },

		methods: {

			openMenu (name) {
				router.push({ name: name })
			},

			preCargaDeProductos () {
				if (localStorage.getItem('cargaDeProductosConStock') === 'CARGADO') {
					this.cargaDeProductosOffline()
				}

				const interval2 = setInterval(() => {
					if (localStorage.getItem('cargaDeProductosConStock') === 'CARGADO') {
						this.cargaDeProductosOffline()
					}
				}, 2000)

				setInterval(() => {
					clearInterval(interval2)
				}, 10000)
			},

			async verificarProductos () {
				console.log('verificarProductos')
				this.spinnerloading = true
				this.preCargaDeProductos()
			},

			async cargaDeProductosOffline () {
				try {
					console.log('cargaDeProductos', 'Obteniendo productos offline desde IndexedDB con Stock')
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					if (listaproductos.length > 150) {
						this.lista_producto_scroll = listaproductos.slice(-100).reverse()
					} else {
						this.lista_producto_scroll = listaproductos
					}
					this.spinnerloading = false
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
			},

			async obtenerDatosDeIndexedDB () {
				return await new Promise((resolve, reject) => {
					database.getData(STORAGE_LISTA_SCROLL_PRODUCTO_STOCK)
					.then(array => {
						resolve(array)
					})
					.catch(error => {
						console.error('Error al obtener el array de IndexedDB:', error)
						reject(error) // Rechaza la promesa con el error obtenido
					})
				})
			},

			async handleBusquedaListaProductos (value) {
				if (value.length > 2) {
					console.log('Búsqueda interna de productos para cotización y consultas')
					
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					if (!listaproductos || listaproductos.length === 0) {
						utils.openNotificationWithIcon('info', '¡Atención!', 'La lista de productos está vacía. Por favor, vuelve a cargarla.', 'topRight')
						return
					}

					const escapedValue = escapeRegExp(value)
					const regex = new RegExp(escapedValue, 'giu')
					const resultado = listaproductos.filter(element => element.detalle.match(regex))

					if (resultado.length > 150) {
						this.lista_producto_scroll = resultado.slice(-100).reverse()
					} else {
						this.lista_producto_scroll = resultado
					}
				}
			},

			async handleChangeListaProductos (value) {
                const opciones = {
                    productoID: value,
                    almacenID: this.almacenid,
                    tipoprecioventaID: this.tipoprecioventaid,
                    tiposalidaID: this.tiposalidaid,
                    listaTipoCambio: this.listacambio,
                    tipocambioID: this.tipocambioid,
                    ventaDecimal: this.config.ventadecimal,
                    habilitarTipoCambioVenta: this.config.habilitartipocambioventa
                }

                const producto = await this.buscarProductoEnLista(opciones)

                if (!producto) {
                    this.mostrarAlertaProductoNoEncontrado()
                    return
                }

				if (parseInt(producto.saldoFisico) === 0) {
					this.mostrarAlertaSaldoCero()
                    return
				}

                const precioVenta = this.calcularPrecioVentaProducto(producto, opciones)
                
                if (parseFloat(precioVenta) === 0) {
                    this.mostrarAlertaPrecioCero()
                    return
                }

                const data = await this.crearProducto(producto, precioVenta, opciones)

                this.$emit('successScrollProductoStock', data)
            },

            async buscarProductoEnLista ({ productoID, almacenID }) {
				try {
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					const producto = listaproductos.find(producto => producto.producto_id === parseInt(productoID) && producto.almacenId === parseInt(almacenID))
					
					if (this.config.istotallistaproductoventa === 'ACTIVADO') {
						this.valueProductoScroll = `${producto.nombre}`
					} else if (this.config.istotallistaproductoventa === 'DESACTIVADO') {
						if (this.config.ismarcaizquierdalistaventa === 'ACTIVADO') {
							this.valueProductoScroll = `${producto.codigo} ${producto.nombre} ${producto.medida} ${producto.unidad} ${producto.marca}`
						} else if (this.config.ismarcaizquierdalistaventa === 'DESACTIVADO') {
							this.valueProductoScroll = `${producto.codigo} ${producto.marca} ${producto.nombre} ${producto.medida} ${producto.unidad}`
						}
					}

					return producto
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
			},

            calcularPrecioVentaProducto (producto, opciones) {
                const precioVenta = producto.precioventas.find(valor => parseInt(valor.tipoprecioventaID) === parseInt(opciones.tipoprecioventaID))

                if (!precioVenta) return 0

				if (parseInt(opciones.tiposalidaID) === 2) {
					return (opciones.habilitarTipoCambioVenta === 'ACTIVADO') ? this.calcularPrecioConFactura(precioVenta, opciones) : precioVenta.precioFacturado
				}

				return (opciones.habilitarTipoCambioVenta === 'ACTIVADO') ? this.calcularPrecioSinFactura(precioVenta, opciones) : precioVenta.precioValor
            },

            calcularPrecioConFactura (precioVenta, opciones) {
                const tipoCambio = opciones.listaTipoCambio.find(tipomoney => parseInt(tipomoney.tipocambio_id) === parseInt(opciones.tipocambioID))
                return tipoCambio ? parseFloat(precioVenta.precioFacturado) * parseFloat(tipoCambio.tipo_de_cambio) : precioVenta.precioFacturado
            },

            calcularPrecioSinFactura (precioVenta, opciones) {
                const tipoCambio = opciones.listaTipoCambio.find(tipomoney => parseInt(tipomoney.tipocambio_id) === parseInt(opciones.tipocambioID))
                return tipoCambio ? parseFloat(precioVenta.precioValor) * parseFloat(tipoCambio.tipo_de_cambio) : precioVenta.precioValor
            },

            async crearProducto (producto, precioVenta, opciones) {
                return {
                    producto_id: producto.producto_id,
                    codigo: producto.codigo,
                    nombre: producto.nombre,
                    medida: producto.medida,
                    marca: producto.marca,
                    saldoFisico: producto.saldoFisico,
                    precioVenta: utils.formatMoney(precioVenta, opciones.ventaDecimal, '.', ''),
                    cantidad: 1,
                    subtotal: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(precioVenta), opciones.ventaDecimal, '.', ''),
                    importeTotalMoneda: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(precioVenta), opciones.ventaDecimal, '.', ''),
					descuentoVenta: utils.formatMoney(0, opciones.ventaDecimal, '.', '')
                }
            },

			mostrarAlertaProductoNoEncontrado () {
                this.isModalNotificacion = true
				this.tipoNotificacion = 'productoNoEncontrado'
            },

			mostrarAlertaPrecioCero () {
				this.isModalNotificacion = true
				this.tipoNotificacion = 'precioVentaNoEncontrado'
			},

			mostrarAlertaSaldoCero () {
				this.isModalNotificacion = true
				this.tipoNotificacion = 'cantidadesNoEncontrado'
				utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Cantidades al Producto segun la Sucursal donde pertenezca...', 'topRight')
			}
		}

	}
</script>

<style>
.ant-select-selection-selected-value {
    float: left !important;
    max-width: 100% !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}

.card {
		border: 2px solid var(--element);
		border-radius: 8px;
		padding: 5px;
		max-width: 100%;
		box-sizing: border-box;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h3 {
  color: #000000;
  font-size: 1.6em;
  font-weight: 700;
  margin-bottom: 20px;
}

.option-description {
  color: #555;
  font-size: 1.1em;
}

ul {
  padding-left: 0px;
}

li {
  margin-bottom: 10px;
}

.note-title {
  font-weight: bold;
  color: #333;
}

.ant-alert-with-description .ant-alert-description {
    display: block;
    padding: 1px 1px 1px 1px !important;
}
</style>
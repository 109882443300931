<template>
    <div>
        <NuevaVenta
            :nuevaventanaventaid="nuevaventanaventaid"
            @abrirNuevaVentana="openNuevaVentana"
            :estadomoduloventa="`AGREGAR`"
            :updateventa="[]"
            :estadoarqueocaja="estadoArqueoCaja"
        />

        <a-modal
			v-model="isArqueoCaja"
			title="Arqueo de Caja"
            :footer="false"
			>
			<a-alert 
				message="Debe leer el mensaje:"
				type="warning"
				banner
				closable
				>
				<p slot="description" v-if="estadoArqueoCaja === 'AbrirCaja'">
					El arqueo de <strong>caja esta cerrado</strong>, debe (abrir) aperturar el arqueo de caja para seguir realizando las operaciones de venta.
				</p>
                <p slot="description" v-if="estadoArqueoCaja === 'CerrarCaja'">
					El arqueo de <strong>caja ha expirado</strong>, debe cerrar y aperturar la caja nuevamente para seguir realizando las operaciones de venta.
				</p>
			</a-alert>
            <br>
            <center>
                <a-button
                    type="primary"
                    size="large"
                    @click="openMenu('arqueocaja')" 
                    v-if="config.habilitararqueocaja === 'ACTIVADO'"
                    > 
                    <a-icon type="shopping-cart" :style="{ fontSize: '20px', fontWeight: '700' }"/> Abrir Arqueo Caja
                </a-button>
            </center>
		</a-modal>
    </div>
</template>
    
<script>
    import Cookies from 'js-cookie'
    import { mapActions, mapGetters } from 'vuex'
	import * as utils from '@/utils/utils'
    import Swal from 'sweetalert2'
    import router from '@/config/router'
    import NuevaVenta from '../Modulos/NuevaVenta.vue'
    import {
		ALMACEN_SISTEMA_KEY_ID
	} from '@/utils/constants'

    export default {
    
        props: [
			'nuevaventanaventaid'
        ],

        components: {
            NuevaVenta
        },

        data () {
			return {
				estadoArqueoCaja: '',
                modalArqueoCaja: false,
                isArqueoCaja: false
			}
		},

        computed: {
			...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config'])
		},

		methods: {
			...mapActions('arqueocaja', ['controlArqueoCajaCerrada']),

            openMenu (name) {
                router.push({ name: name })
            },

            controlArqueoCaja () {
				const almacenID = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
				this.controlArqueoCajaCerrada(almacenID)
                .then(response => {
                    if (response.data.estado === 'CERRADO') {
						this.estadoArqueoCaja = 'AbrirCaja'
                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'EXPIRADO') {
						this.estadoArqueoCaja = 'CerrarCaja'
                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'ABIERTO') {
						this.estadoArqueoCaja = 'Normal'
					}
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
			},

            controlArqueoCajaConMensaje () {
				const almacenID = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
				this.controlArqueoCajaCerrada(almacenID)
                .then(response => {
                    if (response.data.estado === 'CERRADO') {
						this.estadoArqueoCaja = 'AbrirCaja'
                        
                        this.isArqueoCaja = true

                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'EXPIRADO') {
                        this.isArqueoCaja = true

						this.estadoArqueoCaja = 'CerrarCaja'

                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'ABIERTO') {
						this.estadoArqueoCaja = 'Normal'
					}
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
			},

            openNuevaVentana (value) {
                const ruta = this.$router.resolve({
					name: 'venta',
					params: { ventanaID: value }
				})

                window.open(ruta.href, '_blank')
            }
		},

        async mounted () {
            setTimeout(() => {
                if (this.config.habilitararqueocaja === 'ACTIVADO') { // A nivel del Sistema
                    if (this.$can('manager.arqueo.de.caja') === true) { // A nivel de Permisos
                        this.controlArqueoCajaConMensaje()
                        this.controlArqueoCaja()

                        setTimeout(function () {
                            this.controlArqueoCaja()
                        }.bind(this), 3000)

                        setTimeout(function () {
                            this.controlArqueoCaja()
                        }.bind(this), 6000)

                        setTimeout(function () {
                            this.controlArqueoCaja()
                        }.bind(this), 10000)
                    }
                }
            }, 1000)
		}
    }
</script>
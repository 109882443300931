<template>
	<div class="table-responsive no-padding">
        <table class="table table-bordered table-striped">
			<thead>
				<tr>
					<th>STOCK</th>
					<th><center>ARTICULO</center></th>
					<th><center>CANT</center></th>
					<th><center>P.VENTA<br>{{ config.monedasimbolo }}</center></th>
					<th><center>SUBTOTAL<br>{{ config.monedasimbolo }}</center></th>
                    <th><center>DESC<br>{{ config.monedasimbolo }}</center></th>
                    <th><center>TOTAL<br>{{ config.monedasimbolo }}</center></th>
					<th></th>
				</tr>
			</thead>
			<tbody id="listaproducto">
				<tr v-for="(item, i) in tablaproducto" :key="i">
									
					<td><center>{{ item.saldoFisico }}</center></td>
									
					<td v-if="config.istotallistaproductoventa === 'ACTIVADO'"><span style="font-size:14px;">{{ item.nombre }}</span></td>
					<td v-if="config.istotallistaproductoventa === 'DESACTIVADO'"><span style="font-size:14px;">{{ item.codigo }} {{ item.marca }} {{ item.nombre }} {{ item.medida }} </span></td>
									
					<td bgcolor="#eeffd0" style="font-weight: 700;" @click="newCantidad = (i+'_'+item.producto_id); valueCantidad=item.cantidad">
						<label v-show="newCantidad != (i+'_'+item.producto_id)">{{ item.cantidad }}</label>
						<input 
                            style="width: 50px" 
                            class="myinput" 
                            v-if="newCantidad == (i+'_'+item.producto_id)" 
                            v-model="valueCantidad" 
                            @blur="editCantidad(i,item.producto_id); $emit('update')" 
                            @keyup.enter="editCantidad(i,item.producto_id); $emit('update')" 
                            @keyup="validacionCantidad()" 
                            autofocus="autofocus">
					</td>
									
					<template v-if="$can('transaccion.modificar.precio.de.venta')">
						<td bgcolor="#ddf7ff" style="font-weight: 700;" @click="newPrecioVenta = (i+'_'+item.producto_id);valuePrecioVenta=item.precioVenta">
							<label v-show="newPrecioVenta != (i+'_'+item.producto_id)">{{ item.precioVenta }}</label>
							<input  style="width: 60px" class="myinput" v-if="newPrecioVenta == (i+'_'+item.producto_id)" v-model="valuePrecioVenta" v-on:blur="editPrecioVenta(i,item.producto_id); $emit('update')" @keyup.enter="editPrecioVenta(i,item.producto_id); $emit('update')" v-on:keyup="validacionDecimal()" autofocus="autofocus" >
						</td>
					</template>

					<template v-else>
						<td bgcolor="#ddf7ff" style="font-weight: 700;">
							<label>{{ item.precioVenta }}</label>
						</td>
					</template>

                    <td>
						<span class="precios-sistema">{{ item.subtotal }}</span>
					</td>

					<template v-if="$can('transaccion.modificar.descuento.de.venta')">
						<td bgcolor="#fff1f1" style="font-weight: 700;" @click="itemDescuentoVenta = (i+'_'+item.producto_id);valueDescuentoVenta=item.descuentoVenta">
							<label v-show="itemDescuentoVenta != (i+'_'+item.producto_id)">{{ item.descuentoVenta }}</label>
							<input  style="width: 60px" class="myinput" v-if="itemDescuentoVenta == (i+'_'+item.producto_id)" v-model="valueDescuentoVenta" v-on:blur="editItemDescuentoVenta(i,item.producto_id); $emit('update')" @keyup.enter="editItemDescuentoVenta(i,item.producto_id); $emit('update')" v-on:keyup="validacionDecimalDescuentoVenta()" autofocus="autofocus">
						</td>
					</template>

					<template v-else>
						<td bgcolor="#fff1f1" style="font-weight: 700;">
							<label>{{ item.descuentoVenta }}</label>
						</td>
					</template>

                    <td>
						<span class="precios-sistema">{{ item.importeTotalMoneda }}</span>
					</td>

					<td>
						<center>
							<a href="#" @click.prevent="deleteItem(i)">
								<a-icon type="close-circle" :style="{ fontSize: '20px', color: '#EE0808' }" />
							</a>
						</center>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<th class="tabla-foot-prod"></th>
					<th class="tabla-foot-prod"></th>
					<th class="tabla-foot-prod"></th>
					<th class="tabla-foot-prod"><center><font size=3>Total.-</font></center></th>
					<th class="tabla-foot-prod"><center><font size=4 style="color:green;">{{ ViewSubtotal }}</font></center></th>
					<th class="tabla-foot-prod"><center style="color:#9f0303;"><font size=4>{{ ViewDescuentoVenta }}</font></center></th>
                    <th class="tabla-foot-prod">
						<center><font size=4 style="color:#005fa0;">{{ ViewImporteTotalMoneda }}</font></center>
					</th>
				</tr>
			</tfoot>
	    </table>     
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import { 
		STORAGE_LISTA_SCROLL_PRODUCTO_STOCK
	} from '@/utils/constants'
    import * as database from '@/utils/database'

	export default {

        props: {
            updateventa: { type: Array, required: false },
			listaproducto: { type: Object, required: true },
            estadomoduloventa: { type: String, required: true },
            nuevaventanaventaid: { type: Number, required: true }
		},

		data () {
			return {
                utils,
                newCantidad: null,
                valueCantidad: null,
                newPrecioVenta: null,
                valuePrecioVenta: null,
                valueDescuentoVenta: '0.00',
                itemDescuentoVenta: null,
                tablaproducto: [],
                tablaVentanaNuevaID: 'tablaproducto' + this.nuevaventanaventaid
			}
		},

		mounted () {
            if (this.estadomoduloventa === 'AGREGAR') {
				this.getLocalStorage()
                this.enviarInformacion()
			} else if (this.estadomoduloventa === 'EDITAR') {
				this.tablaproducto = []
				setTimeout(function () {
                    this.tablaproducto = this.updateventa.listaventa
                    this.enviarInformacion()
                }.bind(this), 500)
			}
        },

        computed: {
            ...mapGetters('configuracion', ['config']),

            Subtotal () {
                return this.calculateTotal('subtotal')
            },

            DescuentoVenta () {
                return this.calculateTotal('descuentoVenta')
            },

            ImporteTotalMoneda () {
                const importeTotal = this.calculateTotal('importeTotalMoneda')
                if (this.DescuentoVenta === 0) {
                    if (this.Subtotal !== importeTotal) {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : No es igual el proceso del calculo en la lista de venta, vuelva a realizar el calculo del item por favor y verifique los datos nuevamente...', 'topRight')
                    }
                    return importeTotal
                }
                return importeTotal
            },

            // VIEWS PROPERTY COMPUTERS //

            ViewImporteTotalMoneda () {
                return utils.formatMoney(this.ImporteTotalMoneda, this.config.ventadecimal, '.', '')
            },

            ViewSubtotal () {
                return utils.formatMoney(this.Subtotal, this.config.ventadecimal, '.', '')
            },

            ViewDescuentoVenta () {
                return utils.formatMoney(this.DescuentoVenta, this.config.ventadecimal, '.', '')
            }
        },

		methods: {
            calculateTotal (property) {
                const sum = this.tablaproducto.reduce((accumulator, current) => {
                    return accumulator + parseFloat(current[property])
                }, 0)
                return parseFloat(sum)
            },

            enviarInformacion () {
                this.$emit('subtotal', this.Subtotal)
                this.$emit('descuento', this.DescuentoVenta)
                this.$emit('total', this.ImporteTotalMoneda)
                this.$emit('productos', this.tablaproducto)
            },

            getLocalStorage () {
                const datosDB = JSON.parse(localStorage.getItem(this.tablaVentanaNuevaID))
                this.tablaproducto = datosDB === null ? [] : datosDB
                return this.tablaproducto
            },

            saveLocalStorage () {
                localStorage.setItem(this.tablaVentanaNuevaID, JSON.stringify(this.tablaproducto))
            },

            removeItemLocalStorage () {
                localStorage.removeItem(this.tablaVentanaNuevaID)
            },
            
            agregarProductoALaLista (producto) {
                if (this.tablaproducto.length === 0) {
                    this.agregarProductoNuevo(producto)
                } else {
                    const productoExistente = this.tablaproducto.find(element => parseInt(element.producto_id) === parseInt(producto.producto_id))
                    if (productoExistente) {
                        this.notificarProductoExistente(productoExistente)
                    } else {
                        this.agregarProductoExistente(producto)
                    }
                }

                this.enviarInformacion()
            },

            agregarProductoNuevo (producto) {
                this.tablaproducto.push(producto)
                // LOCAL STORAGE
                this.saveLocalStorage()
            },

            agregarProductoExistente (producto) {
                this.tablaproducto.push(producto)
                // LOCAL STORAGE
                this.saveLocalStorage()
            },

            async obtenerDatosDeIndexedDB () {
				return await new Promise((resolve, reject) => {
					database.getData(STORAGE_LISTA_SCROLL_PRODUCTO_STOCK)
					.then(array => {
						resolve(array)
					})
					.catch(error => {
						console.error('Error al obtener el array de IndexedDB:', error)
						reject(error) // Rechaza la promesa con el error obtenido
					})
				})
			},

            async cambiarPrecioCarrito (consulta) {
                const listaProductosAlmacenados = await this.obtenerDatosDeIndexedDB()
				const productosActuales = this.tablaproducto
                const self = this
                productosActuales.map((value, index) => {
                    listaProductosAlmacenados.map((valor, indice) => {
                        if (parseInt(valor.producto_id) === parseInt(value.producto_id)) {
                            const dataprecioventa = valor.precioventas.map(function (values, indexes) {
                                if (parseInt(values.tipoprecioventaID) === parseInt(consulta.tipoprecioventaID)) {
                                    let precioVenta
                                    if (parseInt(consulta.tiposalida_id) === 1) {
                                        precioVenta = self.calculatePrecioVenta(values.precioValor)
                                    } else if (parseInt(consulta.tiposalida_id) === 2) {
                                        precioVenta = self.calculatePrecioVenta(values.precioFacturado)
                                    }
                                    values.precioVenta = precioVenta
                                    return precioVenta
                                }
                            })
                            
                            const preciosValidos = dataprecioventa.filter(x => x !== undefined)

                            if (preciosValidos.length > 0) {
                                const precio = preciosValidos[0]
                                const subtotal = parseInt(value.cantidad) * parseFloat(precio)

                                value.precioVenta = utils.formatMoney(precio, this.config.ventadecimal, '.', '')
                                value.subtotal = utils.formatMoney(subtotal, this.config.ventadecimal, '.', '')
                                value.importeTotalMoneda = utils.formatMoney(subtotal - parseFloat(value.descuentoVenta), this.config.ventadecimal, '.', '')
                                
                                self.saveLocalStorage()
                                this.enviarInformacion()
                            } else {
                                alert('Esperando cambio de precio')
                            }
                        }
                    })
                })
			},

            calculatePrecioVenta (precio) {
                if (this.config.habilitartipocambioventa === 'ACTIVADO') {
                    const listmoney = this.listMoney.filter(function (tipomoney) {
                        return parseInt(tipomoney.tipocambio_id) === parseInt(this.selectedMoney)
                    })

                    return (listmoney.length === 0) ? precio : parseFloat(precio) * parseFloat(listmoney.shift().tipo_de_cambio)
                } else {
                    return precio
                }
            },

            notificarProductoExistente (productoExistente) {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'El Producto ' + productoExistente.nombre + ' ' + productoExistente.medida + ' ya fue elegido...', 'topRight')
            },

            mostrarAlertaPrecioCero () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Precios de Ventas al Producto segun la Sucursal donde pertenezca...', 'topRight')
            },
            
            // Gestion de Tabla del Producto
            validacionCantidad () {
                this.valueCantidad = (this.valueCantidad + '').replace(/[^0-9.]/g, '')
            },

            validacionDecimal () {
                this.valuePrecioVenta = (this.valuePrecioVenta + '').replace(/[^0-9.]/g, '')
            },

			validacionDecimalDescuentoVenta () {
                this.valueDescuentoVenta = (this.valueDescuentoVenta + '').replace(/[^0-9.]/g, '')
            },

            editCantidad (i, j) {
                this.newCantidad = ''
                // Verificar si la cantidad es válida
                if (parseFloat(this.valueCantidad) === 0.000000 || this.valueCantidad === '') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : Introduzca una Cantidad para realizar la venta ...', 'topRight')
                } else if (parseFloat(this.valueCantidad) > parseFloat(this.tablaproducto[i].saldoFisico) && this.estadomoduloventa === 'AGREGAR') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'La cantidad ingresada es mayor al stock del producto. Por favor, ingrese una cantidad menor...', 'topRight')
                } else {
                    // Asignar la cantidad y calcular el subtotal
                    const cantidad = parseFloat(this.valueCantidad)
                    const subtotal = cantidad * parseFloat(this.tablaproducto[i].precioVenta)

                    // Redondeo según la configuración
                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.ventadecimal, '.', '')

                    const descuento = parseFloat(subtotal) - parseFloat(this.valueDescuentoVenta)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.ventadecimal, '.', '') : utils.formatMoney(descuento, this.config.ventadecimal, '.', '')

                    // Actualizar los valores en la tabla
                    this.tablaproducto[i].cantidad = cantidad
                    this.tablaproducto[i].subtotal = formatoSubtotal
                    this.tablaproducto[i].importeTotalMoneda = subtotalActualizado
                    
                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomoduloventa === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            editPrecioVenta (i, j) {
                this.newPrecioVenta = ''

                if (parseFloat(this.valuePrecioVenta) === 0.000000 || this.valuePrecioVenta === '') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : Introduzca un precio de venta para realizar la venta ...', 'topRight')
                } else {
                    const precioVenta = parseFloat(this.valuePrecioVenta)
                    const cantidad = parseFloat(this.tablaproducto[i].cantidad)
                    const subtotal = cantidad * precioVenta

                    const roundedSubtotal = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.redondearNumero(subtotal) : subtotal
                    const formatoSubtotal = utils.formatMoney(roundedSubtotal, this.config.ventadecimal, '.', '')

                    const descuento = subtotal - parseFloat(this.valueDescuentoVenta)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.ventadecimal, '.', '') : utils.formatMoney(descuento, this.config.ventadecimal, '.', '')

                    // Verificar si la validación de editar precio de venta está deshabilitada
                    if (this.config.deshabilitarvalidareditarprecioventa === 'DESACTIVADO' || precioVenta >= parseFloat(this.tablaproducto[i].precioVenta)) {
                        // Asignar el nuevo precio de venta
                        this.tablaproducto[i].precioVenta = utils.formatMoney(precioVenta, this.config.ventadecimal, '.', '')
                    } else {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'El valor ingresado es menor al Precio de Venta actual en el sistema. Por favor, vuelva a ingresar de un nuevo valor igual o mayor...', 'topRight')
                        return false
                    }

                    // Actualizar el subtotal en la tabla
                    this.tablaproducto[i].subtotal = formatoSubtotal
                    this.tablaproducto[i].importeTotalMoneda = subtotalActualizado

                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomoduloventa === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

            editItemDescuentoVenta (i, j) {
                this.itemDescuentoVenta = ''

                if (this.valueDescuentoVenta === '') {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion !!! : Introduzca un descuento para realizar la venta ...', 'topRight')
                } else {
                    // Validar si el descuento es mayor que el subtotal
                    if (parseFloat(this.valueDescuentoVenta) > parseFloat(this.tablaproducto[i].subtotal)) {
                        utils.openNotificationWithIcon('info', 'Mensaje : ', 'El valor de descuento es mayor al Precio de Venta actual. Por favor, vuelva a iniciar de nuevo la venta...', 'topRight')
                        return false
                    } 
                    
                    // Asignar el nuevo descuento de venta
                    this.tablaproducto[i].descuentoVenta = utils.formatMoney(this.valueDescuentoVenta, this.config.ventadecimal, '.', '')
                    
                    // Calcular el nuevo importe this.tablaproducto[i].subtotal) - parseFloat(this.valueDescuentoVentatotal en la moneda
                    const descuento = parseFloat(this.tablaproducto[i].subtotal) - parseFloat(this.valueDescuentoVenta)
                    const subtotalActualizado = this.config.redondeosuperiorventasubtotal === 'ACTIVADO' ? utils.formatMoney(utils.redondearNumero(descuento), this.config.ventadecimal, '.', '') : utils.formatMoney(descuento, this.config.ventadecimal, '.', '')

                    this.tablaproducto[i].importeTotalMoneda = subtotalActualizado

                    // Guardar en el almacenamiento local si estamos en modo de agregar
                    if (this.estadomoduloventa === 'AGREGAR') {
                        this.saveLocalStorage()
                    }

                    this.enviarInformacion()
                }
            },

			deleteItem (i, j) {
                this.tablaproducto.splice(i, 1)

				if (this.estadomoduloventa === 'AGREGAR') {
                	this.saveLocalStorage()
				}

                this.enviarInformacion()
            },

            vaciarListaProductos () {
                this.tablaproducto = []
                localStorage.removeItem(this.tablaVentanaNuevaID)
                this.enviarInformacion()
            }
		}

	}
</script>
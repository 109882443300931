<template>
	<div>
        <a-input 
			size="large"
			v-model="codigodebarra"
			id="CodigoBarrasOnfocus"
			@keypress="buscarYAgregarProducto($event)"
		/>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import * as database from '@/utils/database'
    import { 
		STORAGE_LISTA_SCROLL_PRODUCTO_STOCK
	} from '@/utils/constants'

	export default {

        props: {
			almacenid: { type: Number, required: true },
			tipoprecioventaid: { type: Number, required: true },
			tiposalidaid: { type: Number, required: true },
			tipocambioid: { type: Number, required: true },
			listacambio: { type: Array, required: true }
		},

		data () {
			return {
                utils,
                codigodebarra: '',
                spinnerScroll: false,
                loading: false
			}
		},

		mounted () {
            
        },

		computed: {
            ...mapGetters('configuracion', ['config'])
        },

		methods: {

            async obtenerDatosDeIndexedDB () {
				return await new Promise((resolve, reject) => {
					database.getData(STORAGE_LISTA_SCROLL_PRODUCTO_STOCK)
					.then(array => {
						resolve(array)
					})
					.catch(error => {
						console.error('Error al obtener el array de IndexedDB:', error)
						reject(error) // Rechaza la promesa con el error obtenido
					})
				})
			},

            async buscarYAgregarProducto ($event) {
                if ($event.keyCode === 13) {
                    // Aquí puedes colocar el código que deseas ejecutar al presionar Enter
                    const opciones = {
                        almacenID: this.almacenid,
                        tipoprecioventaID: this.tipoprecioventaid,
                        tiposalidaID: this.tiposalidaid,
                        listaTipoCambio: this.listacambio,
                        tipocambioID: this.tipocambioid,
                        ventaDecimal: this.config.ventadecimal,
                        habilitarTipoCambioVenta: this.config.habilitartipocambioventa
                    }

                    const producto = await this.buscarProductoEnLista(opciones)

                    if (!producto) {
                        this.mostrarAlertaProductoNoEncontrado()
                        return
                    }

                    const precioVenta = this.calcularPrecioVentaProducto(producto, opciones)

                    if (parseFloat(precioVenta) === 0) {
                        this.mostrarAlertaPrecioCero()
                        return
                    }

                    const data = await this.crearProducto(producto, precioVenta, opciones)
                    
                    this.$emit('successProductoStockCodigo', data)
                } else if ($event.charCode && ($event.charCode < 48 || $event.charCode > 57)) {
                    // Impide la entrada de caracteres no numéricos
                    $event.preventDefault()
                }
                this.codigodebarra = ''
            },

            async buscarProductoEnLista ({ productoID, almacenID }) {
                try {
					const listaproductos = await this.obtenerDatosDeIndexedDB()
                    return listaproductos.find(producto => parseInt(producto.codigo) === parseInt(this.codigodebarra) && parseInt(producto.almacenId) === parseInt(almacenID))
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
            },

            calcularPrecioVentaProducto (producto, opciones) {
                const precioVenta = producto.precioventas.find(valor => parseInt(valor.tipoprecioventaID) === parseInt(opciones.tipoprecioventaID))

                if (!precioVenta) return 0

                if (parseInt(opciones.tiposalidaID) === 2) {
					return (opciones.habilitarTipoCambioVenta === 'ACTIVADO') ? this.calcularPrecioConFactura(precioVenta, opciones) : precioVenta.precioFacturado
				}

				return (opciones.habilitarTipoCambioVenta === 'ACTIVADO') ? this.calcularPrecioSinFactura(precioVenta, opciones) : precioVenta.precioValor
            },

            calcularPrecioConFactura (precioVenta, opciones) {
                const tipoCambio = opciones.listaTipoCambio.find(tipomoney => parseInt(tipomoney.tipocambio_id) === parseInt(opciones.tipocambioID))
                return tipoCambio ? parseFloat(precioVenta.precioFacturado) * parseFloat(tipoCambio.tipo_de_cambio) : precioVenta.precioFacturado
            },

            calcularPrecioSinFactura (precioVenta, opciones) {
                const tipoCambio = opciones.listaTipoCambio.find(tipomoney => parseInt(tipomoney.tipocambio_id) === parseInt(opciones.tipocambioID))
                return tipoCambio ? parseFloat(precioVenta.precioValor) * parseFloat(tipoCambio.tipo_de_cambio) : precioVenta.precioValor
            },

            async crearProducto (producto, precioVenta, opciones) {
                return {
                    producto_id: producto.producto_id,
                    codigo: producto.codigo,
                    nombre: producto.nombre,
                    medida: producto.medida,
                    marca: producto.marca,
                    saldoFisico: producto.saldoFisico,
                    precioVenta: utils.formatMoney(precioVenta, opciones.ventaDecimal, '.', ''),
                    cantidad: 1,
                    subtotal: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(precioVenta), opciones.ventaDecimal, '.', ''),
                    importeTotalMoneda: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(precioVenta), opciones.ventaDecimal, '.', ''),
                    descuentoVenta: utils.formatMoney(0, opciones.ventaDecimal, '.', '')
                }
            },

            mostrarAlertaProductoNoEncontrado () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'No existe el Producto Seleccionado en nuestra Sucursal o el Producto no tiene stock en el almacen !!!', 'topRight')
            },

            mostrarAlertaPrecioCero () {
				utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Precios de Ventas al Producto segun la Sucursal donde pertenezca...', 'topRight')
			}
		}

	}
</script>
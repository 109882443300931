<template>
    <div>
        <a-row type="flex">
            <a-col flex="1 1 250px">
                <label for="sucursal" class="control-label">Sucursal:</label>
                <a-select size="large" name="ElegirSucursalGenerado" :value="consulta.almacenID" @change="onSearchSucursal"  style="width: 100%">
                    <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                </a-select>
            </a-col>

            <a-col flex="1 1 250px">
                <label for="proveedor" class="control-label">Proveedor:</label>
                <a-select size="large" :value="consulta.proveedorID" @change="onSearchProveedor" show-search option-filter-prop="children" :filter-option="filterOption" style="width: 100%">
                    <a-select-option v-for="(value, index) in proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                </a-select>
            </a-col>

            <a-col flex="1 1 650px">
                <label>
                    <span class="badge badge-warning">Busqueda en Gral:</span>
                    <span style="font-size:12px;"> Habilitar Especifico  
                        <a-switch style="margin-top: -10px;" :checked="habilitarBusqueda.especifico" @change="onSwitchBusquedaEspecifico"/>
                    </span>
                    <span style="color:green; font-weight:700;"> Busqueda en {{ habilitarBusqueda.descripcion }}</span>
                </label>
                <a-input-search
                    placeholder="Buscar Productos en General"
                    enter-button="Buscar"
                    size="large"
                    @search="onSearchProducto"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 1 50px">
                <label class="control-label">Sync:</label><br>
                <a-button size="large" @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

        <br>

        <a-table 
            :columns="columns" 
            :data-source="listaProductoProveedor" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.producto_id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >

            <template slot="stock" slot-scope="item">
                <span style="color:#0d8b00; font-size:17px; font-weight:700;">{{ item.saldoFisico }}</span>
            </template>

            <template slot="precioventa" slot-scope="item">
                <div style="margin-top: -8px;" class="color-palette-set" v-for="(option, i) in item.precioventa" v-bind:value="option.tipoprecioventaID" :key="i">
                    <div style="background-color:#d8ffd4; padding: 5px;">
                        <a-tooltip placement="topLeft">
                            <template slot="title">
                                <span style="font-size:11px;">{{ option.proveedor }}</span>
                            </template>
                                <a href="#"><span style="color:#0d8b00; font-size:17px; font-weight:700;">{{ option.precioValor }}</span>&nbsp;&nbsp;
                                <span style="font-size:9px; font-weight:700; color:#363636;">{{ option.tipoprecioventa }}</span></a>
                        </a-tooltip>
                    </div>
                </div>
            </template>

            <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                <a-row>
                    <a-col :span="4">
                        <a-list size="small" bordered><a-list-item>ID:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>CATEGORIA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>NOMBRE:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>PROVEEDOR:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MEDIDA:</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>MARCA:</a-list-item></a-list>
                    </a-col>
                    <a-col :span="20">
                        <a-list size="small" bordered><a-list-item>{{ item.producto_id }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.nombreAlmacen }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.codigo }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.categoria }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.nombre }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.proveedor }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.medida }}</a-list-item></a-list>
                        <a-list size="small" bordered><a-list-item>{{ item.marca }}</a-list-item></a-list>
                    </a-col>
                </a-row>
            </span>

            <template slot="action" slot-scope="item">
                <a-dropdown>
                    <a-menu slot="overlay">
                        <a-menu-item  v-if="$can('transaccion.administracion.de.precio.de.ventas')" @click.once="abrirModalAsignarPrecios(item)" :key="buttonKeyNuevaVenta">
                            <a-tooltip>
                                <template slot="title">
                                    <span>{{ item.codigo }}</span>
                                </template>
                                <a-icon type="dollar" :style="{ fontSize: '18px', color: '#0bb006' }"/> <strong>Precios de Venta</strong>
                            </a-tooltip>
                        </a-menu-item>

                        <a-menu-item v-if="$can('transaccion.administracion.de.nuevas.compras')" @click.once="abrirModalInventarioProducto(item)" :key="buttonKeyNuevaCompra">
                            <a-tooltip>
                                <template slot="title">
                                    <span>{{ item.codigo }}</span>
                                </template>
                                <a-icon type="shopping" :style="{ fontSize: '18px', color: '#07b801' }"/> <strong>Nueva Compra</strong>
                            </a-tooltip>
                        </a-menu-item>
                    </a-menu>
                    <a-button> Acciones <a-icon type="down" /> </a-button>
                </a-dropdown>
            </template>
        </a-table>

        <!-- CREAR INVENTARIO PRODUCTO -->
        <a-modal
            v-model="modalInventarioProducto"
            title="GESTION DE COMPRAS E INVENTARIO INICIAL"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1280"
            :zIndex="1045"
            >

            <div class="row mb-12">
                <ComponenteCompra 
                    :monedasimbolo="config.monedasimbolo"
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :preciocifradecimal="config.preciocifradecimal"
                    :preciocifraentera="config.preciocifraentera"
                    :precioreglatres="config.precioreglatres"
                    :dataproducto="compra.dataproducto"
                    :almacenid="1"
                    :proveedorid="1"
                    @successCompra="dataSuccessCompra"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalInventarioProducto = false">
                    CERRAR
                </a-button>
            </template>
        </a-modal>
        <!-- CREAR INVENTARIO PRODUCTO -->

        <!-- EDITAR PRECIOS DE VENTA -->
        <a-modal
            v-model="openModalEditarPrecioVentaCompra"
            title="ASIGNAR PRECIOS DE VENTA AL PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="920"
            :zIndex="1048"
            >

            <div class="mb-12">
                <ModuloPrecioVenta 
                    :monedasimbolo="config.monedasimbolo"
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :preciocifradecimal="config.preciocifradecimal"
                    :preciocifraentera="config.preciocifraentera"
                    :precioreglatres="config.precioreglatres"
                    :almacenid="parseInt(consulta.almacenID)"
                    :productoid="consulta.productoID"
                    :proveedorid="parseInt(consulta.proveedorID)"
                    :utilidad="consulta.utilidad"
                    :producto="consulta.producto"
                    :preciocompra="consulta.precioCosto"
                    @successVenta="dataSuccessPrecioVenta"
                />
            </div>
                        
            <template slot="footer">
                <a-button key="back" @click="openModalEditarPrecioVentaCompra = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>
        <!-- EDITAR PRECIOS DE VENTA -->

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'producto_id', 
            title: 'Codigo', 
            dataIndex: 'codigo',
            key: 'codigo',
            sortDirections: ['ascend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'producto_id',
            title: 'Proveedor', 
            dataIndex: 'proveedor',
            key: 'proveedor',
            width: '17%',
            sortDirections: ['ascend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'producto_id',
            title: 'Nombre', 
            dataIndex: 'nombre',
            key: 'nombre',
            width: '28%',
            sortDirections: ['ascend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'producto_id',
            title: 'Unidad', 
            dataIndex: 'unidad',
            key: 'unidad',
            width: '15%'
        },
        { 
            id: 'producto_id',
            title: 'Medida', 
            dataIndex: 'medida',
            width: '15%',
            sortDirections: ['ascend', 'ascend', false],
            sorter: true,
            ellipsis: false
        },
        { 
            id: 'producto_id',
            title: 'Stock',
            dataIndex: '',
            key: 'stock',
            scopedSlots: { customRender: 'stock' }, 
            width: '8%'
        },
        { 
            id: 'producto_id',
            title: 'P.Venta', 
            dataIndex: '',
            key: 'precioventa',
            scopedSlots: { customRender: 'precioventa' }, 
            width: '25%'
        },
        { 
            id: 'producto_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
    import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'
    import Cookies from 'js-cookie'

    import {
        ALMACEN_SISTEMA_KEY_ID
    } from '@/utils/constants'

    import ComponenteCompra from '../../Compra/Components/ComponenteCompra.vue'
    import ModuloPrecioVenta from '../../Inventario/Modulos/ModuloPrecioVenta.vue'

    export default {

        components: {
            ComponenteCompra,
            ModuloPrecioVenta
        },

        data () {
            return {
                columns,
                consulta: {
                    tipoprecioventaID: '',
                    almacenID: 'Seleccionar',
                    productoID: 'Seleccionar',
                    proveedorID: '',
                    utilidad: '',
                    precioventa: '',
                    producto: {},
                    precioCosto: '',
                    preciofacturado: '',
                    nombreAlmacen: '',
                    keyword: ''
                },
				spinnerloading: false,
				loading: false,
                modalInventarioProducto: false,
                openModalEditarPrecioVentaCompra: false,
                buttonKeyNuevaVenta: 10,
                buttonKeyNuevaCompra: 20,
                habilitarBusqueda: {
                    especifico: false,
                    descripcion: 'GLOBAL'
                },
                compra: {
                    dataproducto: {}
                },
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'producto_id',
                    order: 'ascend'
                }
            }
        },

        mounted () {
            this.getProveedorListado()
            this.getAlmacenes()
            this.paginarResultados()
            this.consulta.proveedorID = 'Seleccionar'
        },

        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('proveedor', ['proveedores']),
            ...mapGetters('producto', ['listaProductoProveedor'])
        },

        methods: {
            ...mapActions('almacen', ['getAlmacenListado']),
            ...mapActions('proveedor', ['getProveedorListado']),
            ...mapActions('producto', ['getProductoProveedor']),

            filterOption (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            getAlmacenes () {
                this.getAlmacenListado()
				.then(response => {
					this.consulta.almacenID = this.user.almacenID.toString()
                    this.consulta.nombreAlmacen = Object.values(this.almacenes).filter(item => item.almacen_id === this.consulta.almacenID).shift().nombre
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            onSearchSucursal (value) {
                this.spinnerloading = true
                this.consulta.keyword = ''
                this.consulta.almacenID = value.key
                this.consulta.nombreAlmacen = value.label
                this.paginarResultados()
            },

            onSearchProveedor (value) {
                this.spinnerloading = true
                this.consulta.keyword = ''
                this.consulta.proveedorID = value
                this.paginarResultados()
            },

            onSwitchBusquedaEspecifico (value) {
                this.habilitarBusqueda.descripcion = (value === true) ? 'ESPECIFICO' : 'GLOBAL'
                this.habilitarBusqueda.especifico = value
            },

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.consulta.keyword = ''
                this.consulta.proveedorID = 'Seleccionar'
                this.paginarResultados()
            },

            onSearchProducto (value) {
                this.spinnerloading = true

                this.paginate.pageSize = 10
                this.paginate.current = 1
                this.paginate.total = 0
                this.paginate.field = 'producto_id'
                this.paginate.order = 'ascend'

                this.consulta.keyword = value
                this.consulta.proveedorID = 'Seleccionar'
                setTimeout(function () { 
                    this.paginarResultados()
                }.bind(this), 500)
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'producto_id',
                    order: (sorter.order) ? sorter.order : 'ascend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'producto_id',
                    order: 'ascend'
                }
            },

            paginarResultados () {
				this.consulta.almacenID = (this.consulta.almacenID === 'Seleccionar') ? Cookies.get(ALMACEN_SISTEMA_KEY_ID) : this.consulta.almacenID
                this.getProductoProveedor({ ...this.paginate, ...this.consulta })
                .then(response => {
                    this.spinnerloading = false
                    this.changePaginate(response.data.meta)
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            /** NUEVA COMPRA */
            abrirModalInventarioProducto (item) {
                this.compra.dataproducto = []

                setTimeout(function () { 
                    this.compra.dataproducto = item
                    this.modalInventarioProducto = true
                    this.buttonKeyNuevaCompra++
                }.bind(this), 500)
            },

            dataSuccessCompra (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                    this.modalInventarioProducto = false
                    this.buttonKeyNuevaCompra++
                }.bind(this), 500)
            },

            /** PRECIO DE VENTAS */
            abrirModalAsignarPrecios (item) {
				if (parseInt(this.consulta.almacenID) !== parseInt(item.almacenId) && parseInt(this.consulta.proveedorID) !== parseInt(item.proveedor_id)) {
					utils.openNotificationWithIcon('info', 'Mensaje : ', 'Debe elegir el Almacen o Sucursal y el Proveedor a la que pertenece el producto para crear el precio de venta:).', 'topRight')
				} else {
                    if (Object.entries(item.stocks).length > 0) {
                        // Para crear precios de venta es necesario un stock actual igual o mayor a cero
                        this.consulta = []

                        setTimeout(function () { 
                            this.consulta.producto = item
                            this.consulta.almacenID = item.almacenId.toString()
                            this.consulta.productoID = item.producto_id
                            this.consulta.proveedorID = item.proveedor_id.toString()
                            this.consulta.utilidad = item.utilidad
                            this.consulta.precioCosto = Object.values(item.stocks).filter(x => typeof x !== 'undefined').shift().costo
                            this.openModalEditarPrecioVentaCompra = true
                        }.bind(this), 500)
                    } else {
                        utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Para crear precios de venta es necesario tener un stock igual o mayor a cero', 'topRight')
                    }
                }
                this.buttonKeyNuevaVenta++
			},

            dataSuccessPrecioVenta (newValue) {
                setTimeout(function () { 
                    this.paginarResultados()
                }.bind(this), 500)
                this.buttonKeyNuevaVenta++
            }
		}
    }
</script>
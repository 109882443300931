<template>
    <div>
        <!--PRODUCTOS-->
        <template v-if="tipoproducto === 'agregar' || tipoproducto === 'editar'">
            <a-tabs default-active-key="1">
                <a-tab-pane key="1" tab="Principal">

                    <a-row type="flex" :gutter="8">
                        <a-col flex="1 1 50%">
                            <template v-if="tipo === 'agregar'">
                                <VueFileAgent
                                    ref="vueFileAgent"
                                    :multiple="false"
                                    :deletable="true"
                                    :meta="true"
                                    :accept="'.jpg, .jpeg, .png'"
                                    :maxSize="'10MB'"
                                    :maxFiles="1"
                                    :helpText="'Agregue sus Imágenes'"
                                    :errorText="{
                                        type: 'Tipo de archivo invalido. Solo se permiten imágenes o zip.',
                                        size: 'Los archivos no deben exceder los 10 MB de tamaño.',
                                    }"
                                    @select="filesSelected($event)"
                                    @beforedelete="onBeforeDelete($event)"
                                    @delete="fileDeleted($event)"
                                    >						
                                </VueFileAgent>
                            </template>

                            <template v-if="tipo === 'editar'">
                                <a-upload
                                    :multiple="false"
                                    :customRequest="guardarImagenProducto"
                                    :remove="handleRemove"
                                    :before-upload="beforeUpload"
                                    :file-list="fileList"
                                    name="file"
                                    list-type="picture"
                                    accept="image/jpeg, image/png, image/jpg"
                                    @change="handleChange"
                                    @preview="handlePreview"
                                    >
                                    <a-button> <a-icon type="upload" /> Cargar Imagen </a-button>
                                </a-upload>
                                <a-modal 
                                    :visible="previewVisible" 
                                    :footer="null"
                                    :width="820"
                                    :zIndex="1110"
                                    :dialog-style="{ top: '5px' }"
                                    @cancel="handleCancel"
                                    >
                                    <img style="width: 100%" :src="previewImage" />
                                </a-modal>
                            </template>
                        </a-col>

                        <a-col flex="1 1 50%">
                            <template v-if="form.codigo_normal === false">
                                <a-col flex="1 1 100%">
                                    <label class="texto-base-12"><a href="#" size="small" type="primary" class="texto-14" @click.prevent="generarCodigoBarrasEdit()"><a-icon type="barcode" :style="{ fontSize: '18px', color: '#fffff' }" />  Generar Código de Barras</a></label>
                                    <a-input size="large" v-model="producto.codigo" @keypress="GetBarCodeEdit($event)" placeholder="Código de Barras" onfocus="myFunction(this)"/>
                                </a-col>
                            </template>
                            <template v-if="form.codigo_normal === true">
                                <a-col flex="1 1 100%">
                                    <label class="texto-base-12">Código Normal:</label>
                                    <a-input size="large" v-model="producto.codigo" @keypress="GetCode($event)" placeholder="Codigo de Barras" onfocus="myFunction(this)"/>
                                </a-col>
                            </template>
                            
                            <template v-if="form.mostrarCodigoProducto === true">
                                <a-col flex="1 1 100%">
                                    <label class="texto-base-12">Código de Producto:</label>
                                    <a-input size="large" v-model="producto.codigoProducto" @keypress="GetCode($event)" placeholder="Codigo de Producto" onfocus="myFunction(this)"/>
                                </a-col>
                            </template>

                            <template v-if="form.mostrarNumeroSerie === true">
                                <a-col flex="1 1 100%">
                                    <label class="texto-base-12">Número de Serie:</label>
                                    <a-input size="large" v-model="producto.numeroSerie" @keypress="GetCode($event)" placeholder="Número de Serie" onfocus="myFunction(this)"/>
                                </a-col>
                            </template>
                        </a-col>

                        <a-col flex="1 1 100%">
                            <label class="texto-base-12">Nombre Producto: <span class="texto-10"> Obligatorio *</span></label>
                            <a-input size="large" v-model="producto.nombre" placeholder="Nombre del Producto" onfocus="myFunction(this)"/>
                        </a-col>

                        <template v-if="tipo === 'agregar'">
                            <a-col flex="1 1 100%">
                                <label class="texto-base-12">Categoria: <span class="texto-10"> *</span></label>
                                &nbsp;
                                <span v-if="form.subcategoria === true" style="font-size:12px; color:green;"> Habilitado SubCategoria</span>
                                &nbsp;
                                <a href="#" size="small" type="primary" class="texto-14" @click.prevent="abriModalSecundary('categoria')"><a-icon type="plus" :style="{ fontSize: '18px', color: '#fffff' }" />  Agregar Categoria</a>

                                <a-select 
                                    label-in-value 
                                    :value="{ key: producto.categoriaID }" 
                                    @change="onSelectCategoria" 
                                    show-search
                                    option-filter-prop="children" 
                                    :filter-option="filterOptionCategoria" 
                                    style="width:100%"
                                    size="large"
                                    >
                                    <a-select-option v-for="(value, index) in categorias" :key="index" :value="value.categoria_id"> {{ value.nombre }}</a-select-option>
                                </a-select>
                                <br>
                                <a-alert v-if="producto.nombreSubCategoria" :message="'SubCategoria : ' + producto.nombreSubCategoria" type="success" show-icon />
                            </a-col>
                        </template>

                        <a-col flex="1 1 50%">
                            <label class="texto-base-12">Medida: <span class="texto-10"> *</span></label>
                            <a-input size="large" v-model="producto.medida" placeholder="100 ml" onfocus="myFunction(this)"/>
                        </a-col>

                        <a-col flex="1 1 50%">
                            <label class="texto-base-12">Utilidad (%): <span class="texto-10"> *</span></label>
                            <a-input-number size="large" :min="1" :max="100" :default-value="producto.porcentajeUtilidad" @change="onChangeMargenGanancia" onfocus="myFunction(this)" style="width: 100%"/>
                        </a-col>
                    </a-row>
                        
                    <a-row type="flex" :gutter="8">
                        <template v-if="tipo === 'agregar'">
                            <a-divider orientation="left"> Inventario Inicial (Stock) </a-divider>

                            <template v-if="form.producto_inventariable === true">
                                <a-col flex="1 1 70%">
                                    <label class="texto-base-12">Sucursal: <span class="texto-10"> *</span></label>
                                    &nbsp;
                                    <a href="#" size="small" type="primary" class="texto-14" @click.prevent="abriModalSecundary('almacen')"><a-icon type="plus" :style="{ fontSize: '18px', color: '#fffff' }" />  Agregar Sucursal</a>
                                    <a-select 
                                        size="large" 
                                        :value="producto.almacenID" 
                                        @change="onSelectSucursal"  
                                        style="width: 100%"
                                        >
                                        <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                                    </a-select>
                                </a-col>

                                <a-col flex="1 1 30%">
                                    <label class="texto-base-12">Stock Minimo: <span class="texto-10"> *</span></label>
                                    <a-input-number size="large" :min="1" :default-value="producto.stockMinimo" @change="onChangeStockMinimo" onfocus="myFunction(this)" style="width: 100%"/>
                                </a-col>
                            </template>

                            <a-col flex="1 1 50%">
                                <label class="texto-base-12">Precio Compra: <span class="texto-10"> *</span></label>
                                <a-input-number size="large" :min="0" :value="producto.costo" @change="onChangeCosto" onfocus="myFunction(this)" placeholder="0.00" style="width: 100%"/>
                            </a-col>

                            <a-col flex="1 1 50%">
                                <label class="texto-base-12">Cantidad a Ingresar: <span class="texto-10"> *</span></label>
                                <a-input-number size="large" :min="0" :value="producto.cantidad" @change="onChangeCantidad" onfocus="myFunction(this)" placeholder="0" style="width: 100%"/>
                            </a-col>

                            <a-col flex="1 1 100%">
                                <center><label class="texto-base-12">Tipo precio de venta</label></center>
                                <a-radio-group v-model="asignacion.tipoprecioventa" @change="onSelectTipoPrecioVenta" button-style="solid">
                                    <a-radio-button v-for="(item, index) in lista_tipoprecioventa" :key="index" :value="item">
                                        <a-icon type="plus" /> {{ item.nombre }}
                                    </a-radio-button>
                                </a-radio-group>
                            </a-col>

                            <a-col flex="1 1 100%">
                                <section v-for="(value, index) in lista_precios_venta" :key="index">
                                    <a-divider orientation="left"><span class="texto-12">{{ value.nombreTipoPrecioVenta }}</span></a-divider>
                                    <a-col :span="11">
                                        <label class="texto-base-12">Precio Venta: <span class="texto-10"> *</span></label>
                                        <a-input-number size="large" :min="0" :value="lista_precios_venta[index].precioValor" @change="onChangePrecioVenta($event, index)" onfocus="myFunction(this)" placeholder="0.00" style="width: 100%"/>
                                    </a-col>
                                    <a-col :span="11">
                                        <label class="texto-base-12">Precio V. Facturado: <span class="texto-10"> *</span></label>
                                        <a-input-number size="large" :min="0" :value="lista_precios_venta[index].precioFacturado" @change="onChangePrecioVentaFacturado($event, index)" onfocus="myFunction(this)" placeholder="0.00" style="width: 100%"/>
                                    </a-col>
                                    <a-col :span="2">
                                        <a href="#" @click.prevent="deleteItemTipoPrecioVenta(index)">
                                            <a-icon type="close-circle" :style="{ fontSize: '25px', color: '#EE0808', top: '26px', marginTop: '33px' }" />
                                        </a>
                                    </a-col>
                                </section>
                            </a-col>
                        </template>
                    </a-row>
                </a-tab-pane>

                <a-tab-pane key="2" tab="Avanzado" force-render>
                    <a-row type="flex" :gutter="8">

                        <template v-if="tipo === 'agregar'">
                            <a-col flex="1 1 100%">
                                <label class="texto-base-12">Proveedor: <span class="texto-10"> Obligatorio *</span></label>
                                &nbsp;&nbsp;
                                <a href="#" size="small" type="primary" class="texto-14" @click.prevent="abriModalSecundary('proveedor')"><a-icon type="plus" :style="{ fontSize: '18px', color: '#fffff' }" />  Agregar Proveedor</a>
                                <a-select
                                    :dropdownMatchSelectWidth="true"
                                    :value="producto.proveedorID" 
                                    @change="onSelectProveedor" 
                                    show-search 
                                    option-filter-prop="children"
                                    :filter-option="filterOptionProveedor" 
                                    style="width:100%"
                                    size="large"
                                    >
                                    <a-select-option v-for="(value, index) in lista_proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                                </a-select>
                            </a-col>
                        
                            <a-col flex="1 1 50%">
                                <label class="texto-base-12">Marca: <span class="texto-10">*</span></label>
                                &nbsp;
                                <a href="#" size="small" type="primary" class="texto-14" @click.prevent="abriModalSecundary('marca')"><a-icon type="plus" :style="{ fontSize: '18px', color: '#fffff' }" />  Agregar Marca</a>
                                <a-select 
                                    :value="producto.marcaID" 
                                    @change="onSelectMarca" 
                                    show-search 
                                    option-filter-prop="children" 
                                    :filter-option="filterOptionMarca" 
                                    style="width:100%"
                                    size="large"
                                    >
                                    <a-select-option v-for="(value, index) in lista_marcas" :key="index" :value="value.marca_id"> {{ value.nombre }}</a-select-option>
                                </a-select>
                            </a-col>

                            <a-col flex="1 1 50%">
                                <label class="texto-base-12">Unidad: <span class="texto-10">*</span></label>
                                &nbsp;
                                <a href="#" size="small" type="primary" class="texto-14" @click.prevent="abriModalSecundary('unidad')"><a-icon type="plus" :style="{ fontSize: '18px', color: '#fffff' }" />  Agregar Unidad</a>
                                <a-select 
                                    :value="producto.unidadID" 
                                    @change="onSelectUnidad" 
                                    show-search 
                                    option-filter-prop="children" 
                                    :filter-option="filterOptionUnidad" 
                                    style="width:100%"
                                    size="large"
                                    >
                                    <a-select-option v-for="(value, index) in lista_unidades" :key="index" :value="value.unidad_id"> {{ value.nombre }}</a-select-option>
                                </a-select>
                            </a-col>

                            <template v-if="form.fecha_vencimiento === true">
                                <a-col flex="1 1 50%">
                                    <label class="texto-base-12">Fecha elaboracion:  <span class="texto-10"> Opcional *</span></label>
                                    <a-date-picker size="large" :value="getDateFormat(producto.elaboracion)" @change="onDateElaboracion" :locale="locale" style="width: 100%"/>
                                </a-col>

                                <a-col flex="1 1 50%">
                                    <label class="texto-base-12">Fecha expiracion:  <span class="texto-10"> Opcional *</span></label>
                                    <a-date-picker size="large" :value="getDateFormat(producto.vencimiento)" @change="onDateVencimiento" :locale="locale" style="width: 100%"/>
                                </a-col>
                            </template>
                        </template>

                        <a-col flex="1 1 100%">
                            <label class="texto-base-12">Descripcion: <span class="texto-10"> Opcional *</span></label>
                            <wysiwyg v-model="producto.descripcion"></wysiwyg>
                        </a-col>

                        <a-col flex="1 1 100%">
                            <a-alert message="Configuracion Tienda Virtual" type="success">
                                <span slot="description">
                                    <p>
                                            <span style="font-size:11px; color:black;"> Habilitar Visualizacion?  <a-switch :checked="form.visualizacion" @change="onSwitchVisualizacion" /></span><br>
                                            <span style="font-size:11px; color:black;"> Mostrar Stock?  <a-switch :checked="form.mostrarStock" @change="onSwitchStock" /></span>
                                            <span style="font-size:11px; color:black;"> Mostrar Precio Venta?  <a-switch :checked="form.mostrarPrecioVenta" @change="onSwitchPrecioVenta" /></span><br><br>
                                            <label>Descuento Porcentaje Venta Online %:</label>
                                            <a-input-number 
                                                :default-value="producto.descuento"
                                                :min="0"
                                                :max="100"
                                                :step="100"
                                                :formatter="value => `${value}%`"
                                                :parser="value => value.replace('%', '')"
                                                @change="onChangeDescuento"
                                                size="large" 
                                                onfocus="myFunction(this)" 
                                                style="width: 30%"
                                                />
                                    </p>
                                </span>
                            </a-alert>
                        </a-col>
                    </a-row>
                </a-tab-pane>

                <a-tab-pane key="3" tab="Configuracion">
                    <a-timeline>
                        <a-timeline-item>Habilitar Codigo Normal ? <a-switch :checked="form.codigo_normal" @change="onSwitchCodigoNormal" /></a-timeline-item>
                        <a-timeline-item>Habilitar Codigo de Producto ?  <a-switch :checked="form.mostrarCodigoProducto" @change="onSwitchCodigoProducto" /></a-timeline-item>
                        <a-timeline-item>Habilitar Número de Serie ?  <a-switch :checked="form.mostrarNumeroSerie" @change="onSwitchNumeroSerie" /></a-timeline-item>
                        <a-timeline-item>Habilitar Subcategorias ? <a-switch :checked="form.subcategoria" @change="onSwitchSubCategoria" /></a-timeline-item>
                        <a-timeline-item>Habilitar Fechas de Vencimientos ? <a-switch :checked="form.fecha_vencimiento" @change="onSwitchFechaVencimiento" /></a-timeline-item>
                        <a-timeline-item>Habilitar Seleccion de Sucursales ?  <a-switch :checked="form.producto_inventariable" @change="onSwitchProductoInventariable" /></a-timeline-item>
                    </a-timeline>
                </a-tab-pane>
            </a-tabs>

            <br>

            <a-button size="large" type="primary" block v-if="tipoproducto === 'agregar'" @click.once="guardarProducto()" :key="buttonKeyGuardarProducto">
                <a-icon type="plus" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> Guardar Producto
            </a-button>

            <a-button size="large" type="primary" block v-if="tipoproducto === 'editar'" @click.once="editarProducto()" :key="buttonKeyActualizarProducto">
                <a-icon type="plus" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> Actualizar Producto
            </a-button>
        </template>

        <template v-if="tipoproducto === 'eliminar'">
            <div class="mb-12">
                <h3 style="color:#139e6a;">Producto Registrado </h3>
                <hr>
                <table width="100%" border="1">
                    <tr>
                        <td style="font-size:13px; font-weight:700;">CODIGO: </td>
                        <td style="font-size:13px; font-weight:700; color:black;">{{ producto.codigo }}</td>
                    </tr>
                    <tr>
                        <td style="font-size:13px; font-weight:700;">NOMBRE: </td>
                        <td colspan="3" style="font-size:13px; font-weight:700; color:black;">{{ producto.nombre }}</td>
                    </tr>
                </table>

                <br>

                <a-button size="large" type="danger" block v-if="tipoproducto === 'eliminar'" @click.once="eliminarProducto()" :key="buttonKeyEliminarProducto">
                    <a-icon type="delete" :style="{ fontSize: '22px', color: '#FFFFFF', fontWeight: '700' }"/> ELIMINAR PRODUCTO
                </a-button>
            </div>
        </template>

        <!--PROVEEDORES-->
        <a-modal
            v-model="modalProveedor"
            title="GESTION DE PROVEEDOR"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreProveedor" placeholder="Nombre del Proveedor" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalProveedor = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarProveedor()" :key="buttonKey" type="primary" :loading="loading">GUARDAR PROVEEDOR</a-button>
            </template>
        </a-modal>
    
        <!--CATEGORIAS-->
        <a-modal
            v-model="modalCategoria"
            title="GESTION DE CATEGORIA"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreCategoria" placeholder="Nombre de la Categoria" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalCategoria = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarCategoria()" :key="buttonKey" type="primary" :loading="loading">GUARDAR CATEGORIA</a-button>
            </template>
        </a-modal>

         <!--SUB CATEGORIAS-->
         <a-modal
            v-model="modalVisualSubCategoria"
            title="GESTION DE SUBCATEGORIA"
            :footer="false"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <span>Categoria :</span>
                <h1 style="margin-top: 2px;"> {{ producto.nombreCategoria }}</h1>
                <a-col :span="20">
                    <label for="almacen" class="control-label">SubCategoria: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-select 
                        label-in-value 
                        :value="{ key: producto.subcategoriaID }" 
                        @change="onSelectSubCategoria" 
                        show-search
                        option-filter-prop="children" 
                        :filter-option="filterOptionSubCategoria" 
                        style="width:100%"
                        size="large"
                        >
                        <a-select-option v-for="(value, index) in subcategorias" :key="index" :value="value.subcategoria_id"> {{ value.nombre }}</a-select-option>
                    </a-select>
                </a-col>

                <a-col :span="3">
                    <a-button 
                        size="large" 
                        type="dashed"
                        style="top: 26px;"
                        @click="abriModalSecundary('subcategoria')"
                        >
                        <a-icon type="plus" :style="{ fontSize: '18px', color: '#08c' }" />
                    </a-button>
                </a-col>
            </div>
        </a-modal>

        <!--MODAL CREAR SUB CATEGORIAS-->
        <a-modal
            v-model="modalSubCategoria"
            title="CREAR SUBCATEGORIAS"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1055"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreSubCategoria" placeholder="Nombre de la SubCategoria" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalSubCategoria = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarSubCategoria()" :key="buttonKey" type="primary" :loading="loading">GUARDAR SUBCATEGORIA</a-button>
            </template>
        </a-modal>
    
        <!--UNIDADES-->
        <a-modal
            v-model="modalUnidad"
            title="GESTION DE UNIDAD"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreUnidad" placeholder="Nombre de la Unidad" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalUnidad = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarUnidad()" :key="buttonKey" type="primary" :loading="loading">GUARDAR UNIDAD</a-button>
            </template>
        </a-modal>
    
        <!--MARCAS-->
        <a-modal
            v-model="modalMarca"
            title="GESTION DE MARCA"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreMarca" placeholder="Nombre de la Marca" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalMarca = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarMarca()" :key="buttonKey" type="primary" :loading="loading">GUARDAR MARCA</a-button>
            </template>
        </a-modal>
    
        <!--PROCEDENCIAS-->
        <a-modal
            v-model="modalProcedencia"
            title="GESTION DE PROCEDENCIA"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreProcedencia" placeholder="Nombre de la Procedencia" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalProcedencia = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarProcedencia()" :key="buttonKey" type="primary" :loading="loading">GUARDAR PROCEDENCIA</a-button>
            </template>
        </a-modal>
    
        <!--ALMACENES-->
        <a-modal
            v-model="modalAlmacen"
            title="GESTION DE SUCURSAL"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="producto.nombreAlmacen" placeholder="Nombre de la Sucursal" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalAlmacen = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarAlmacen()" :key="buttonKey" type="primary" :loading="loading">GUARDAR SUCURSAL</a-button>
            </template>
        </a-modal>
    
        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
            <div style="margin-top: 250px;">
                <VueSimpleSpinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..." />
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    
        <div v-if="spinnerLoadingIndicator == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
            <div style="margin-top: 250px;">
                <VueSimpleSpinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..." />
                <br><br>
                <center><progress-bar :progress="producto.progress_upload"></progress-bar></center>
                <br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerLoadingIndicator = false"><i class="fas fa-sync"></i> Cancelar y Volver a Intentar</button></center>
            </div>
        </div>
    </div>
</template>
    
<script>
	import Vue from 'vue'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
    import Cookies from 'js-cookie'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
	import Compressor from 'compressorjs'
    import ProgressBar from '../../../plugins/ProgressBar.vue'
    import VueSimpleSpinner from 'vue-simple-spinner'
    import VueFileAgent from 'vue-file-agent'
    import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
    import { VueEditor } from 'vue2-editor'
    import wysiwyg from 'vue-wysiwyg'
    import { mapGetters, mapActions } from 'vuex'

    Vue.use(VueFileAgent)
    Vue.use(wysiwyg, {
        hideModules: { 
            image: true,
            code: true,
            removeFormat: true 
        }
    })

    import { 
		URI_TIPOPRECIOVENTA_LISTAR,
        URI_PRODUCTO_BARCODE,
        URI_PRODUCTO_CODE,
        URI_UNIDAD_LISTAR,
        URI_MARCA_LISTAR,
        URI_PROCEDENCIA_LISTAR,
        URI_ESTADO_LISTAR,
        URI_ALMACEN_LISTAR_LAST,
        URI_PROVEEDOR_LISTAR_LAST,
        URI_UNIDAD_GUARDAR,
        URI_CATEGORIA_GUARDAR,
        URI_MARCA_GUARDAR,
        URI_PROCEDENCIA_GUARDAR,
        URI_PROVEEDOR_GUARDAR_POST,
        URI_ALMACEN_GUARDAR_POST,
        URI_IMAGENPRODUCTO_GUARDAR,
        URI_IMAGENPRODUCTO_ELIMINAR,
        URI_PRODUCTO_GUARDAR,
        URI_PRODUCTO_EDITAR,
        URI_PRODUCTO_ELIMINAR,
        ALMACEN_SISTEMA_KEY_ID
	} from '@/utils/constants'

    import * as utils from '@/utils/utils'
    
    function blob2file (blobData, name) {
        const fd = new FormData()
        fd.set('a', blobData, name)
        return fd.get('a')
    }
    
    function getBase64File (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }
    
    function roundToTwo (num) {    
        return +(Math.round(num + 'e+2') + 'e-2')
    }
    
    function getRandomChar (stack, cantidad) {
        var choice = ''
        for (var i = 0; i < cantidad; i++) {
            var indice = Math.floor((Math.random() * (stack.length - 1)) + 1)
            choice += stack[indice]
        }
        return choice
    }
    
    export default {
    
        props: {
            tipoproducto: { type: String, required: true },
            dataproducto: { required: true },
            almacenid: { type: Number, required: true },
            proveedorid: { type: String, required: true },
            generarlenghtcodigobarra: { type: Number, required: true }
        },
    
        components: {
            VueSimpleSpinner,
            ProgressBar
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                utils,
                producto: {
                    producto_id: '',
                    codigo: '',
                    codigoProducto: '',
                    numeroSerie: '',
                    nombre: '',
                    proveedorID: '',
                    categoriaID: 'Seleccionar',
                    subcategoriaID: 'Seleccionar',
                    marcaID: '',
                    estadoID: '1',
                    descripcion: '',
                    medida: 'S/N',
                    unidadID: '',
                    procedenciaID: '',
                    elaboracion: '',
                    vencimiento: '',
                    stockMinimo: '10',
                    loteProducto: '0',
                    porcentajeUtilidad: '33',
                    almacenID: this.almacenid.toString(),
                    costo: '',
                    cantidad: '',
                    nombreProveedor: '',
                    nombreCategoria: '',
                    nombreSubCategoria: '',
                    nombreUnidad: '',
                    nombreMarca: '',
                    nombreProcedencia: '',
                    nombreAlmacen: '',
                    files: [],
                    progress_upload: 0,
                    estadoVisualizacion: 'HABILITADO',
                    estadoStock: 'HABILITADO',
                    estadoPrecioVenta: 'HABILITADO',
                    descuento: 0
                },
                lista_unidades: [],
                lista_marcas: [],
                lista_procedencias: [],
                lista_categorias: [],
                lista_estados: [],
                lista_proveedores: [],
                lista_almacenes: [],
                codigo_barra: '',
                codigo_observacion: '',
                codigo_default: '/static/assets/images/codigo.jpg',
                isBlock: false,
                modal: false,
                tipo: '',
                buttonKey: 1,
                buttonKeyGuardarProducto: 10,
                buttonKeyActualizarProducto: 20,
                buttonKeyEliminarProducto: 30,
                showModal: false,
                spinnerloading: false,
                modalCategoria: false,
                modalUnidad: false,
                modalMarca: false,
                modalProcedencia: false,
                modalProveedor: false,
                modalAlmacen: false,
                loading: false,
                spinnerLoadingIndicator: false,
                form: {
                    codigo_normal: false,
                    codigo_automatico: false,
                    opciones_avanzadas: false,
                    fecha_vencimiento: false,
                    producto_inventariable: false,
                    registrar_stock: false,
                    subcategoria: false,
                    visualizacion: false,
                    mostrarStock: false,
                    mostrarPrecioVenta: false,
                    mostrarNumeroSerie: false,
                    mostrarCodigoProducto: false
                },
                compra: {
                    compra_id: ''
                },
                /** UPLOAD DE FILE PRODUCTO */
                imageUrl: '',
                fileList: [],
                previewVisible: false,
                previewImage: '',
                lista_tipoprecioventa: [],
                asignacion: {
                    tipoprecioventaID: 'Seleccionar',
                    nombreTipoPrecioVenta: '',
                    tipoprecioventa: null
                },
                lista_precios_venta: [],
                modalVisualSubCategoria: false,
                modalSubCategoria: false,
                listaVisualizacion: [
                    { id: 1, nombre: 'HABILITADO' },
                    { id: 2, nombre: 'DESHABILITADO' }
                ],
                listaStock: [
                    { id: 1, nombre: 'HABILITADO' },
                    { id: 2, nombre: 'DESHABILITADO' }
                ],
                listaPrecioVenta: [
                    { id: 1, nombre: 'HABILITADO' },
                    { id: 2, nombre: 'DESHABILITADO' }
                ],
                consulta: {
					almacen_id: ''
				}
            }
        }, 
    
        mounted () {
            this.getAlmacen()
            this.getProveedor()
            this.getUnidad()
            this.getMarca()
            this.getProcedencia()
            this.getCategorias()
            this.getEstado()
            this.getTipoPrecioVenta()

            setTimeout(function () { 
                this.initModuloProducto(this.dataproducto, this.tipoproducto)
            }.bind(this), 300)
        },
    
        watch: {
            modal () {
                if (this.modal) {
                    Vue.nextTick().then(() => this.$refs.input.focus())
                }
            }
        },

        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('categoria', ['categorias']),
            ...mapGetters('subcategoria', ['subcategorias'])
        },
    
        methods: {
            ...mapActions('categoria', ['getCategoria']),
            ...mapActions('subcategoria', ['create', 'getSubCategoria']),
            ...mapActions('producto', ['getListaScrollMasivoProducto', 'getListaScrollMasivoProductoStock']),

            loadTextArea () {
                
            },
    
            getDateFormat (date) {
                var d = moment(date)
                return date && d.isValid() ? d : ''
            },
    
            getCleanNumbersInString (string) {
                var tmp = string.split('')
                var map = tmp.map(function (current) {
                    if (!isNaN(parseInt(current))) {
                    return current
                    }
                })
                var numbers = map.filter(function (value) {
                    return value !== undefined
                })
                return numbers.join('')
            },

            resetProducto () {
                this.producto.producto_id = ''
                this.producto.codigo = ''
                this.producto.nombre = ''
                this.producto.descripcion = ''
                this.producto.medida = 'S/N'
                this.producto.elaboracion = ''
                this.producto.vencimiento = ''
                this.producto.stockMinimo = '10'
                this.producto.loteProducto = '0'
                this.producto.porcentajeUtilidad = '33'
                this.producto.costo = ''
                this.producto.cantidad = ''
                this.producto.precioventa = ''
                this.producto.precioventafacturado = ''
                this.producto.estadoVisualizacion = 'HABILITADO'
                this.producto.estadoStock = 'HABILITADO'
                this.producto.estadoPrecioVenta = 'HABILITADO'
                this.producto.descuento = 10
            },

            getTipoPrecioVenta () {
                axios.get(URI_TIPOPRECIOVENTA_LISTAR)
                .then(response => {
                    const lista = response.data
                    this.lista_tipoprecioventa = lista
                    const data = lista.filter(x => typeof x !== 'undefined').shift()
                    this.lista_precios_venta.push({
                        tipoprecioventaID: data.tipoprecioventa_id,
                        nombreTipoPrecioVenta: data.nombre,
                        precioValor: '0.00',
                        precioFacturado: '0.00'
                    })
                    this.asignacion.tipoprecioventa = data
                    this.asignacion.tipoprecioventaID = data.tipoprecioventa_id
                    this.asignacion.nombreTipoPrecioVenta = data.nombre
                })
                .catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            initModuloProducto (data, tipo) {
                this.tipo = tipo
                if (tipo === 'editar') {
                    this.resetProducto()

                    this.producto.producto_id = data.producto_id
                    this.producto.codigo = data.codigo
                    this.producto.codigoProducto = data.codigoProducto
                    this.producto.numeroSerie = data.codigoSerial
                    this.producto.nombre = data.nombre
                    this.producto.proveedorID = data.proveedorID.toString()
                    this.producto.categoriaID = data.categoriaID.toString()
                    this.producto.subcategoriaID = '1' // data.subcategoriaID.toString()
                    this.producto.marcaID = data.marcaID.toString()
                    this.producto.estadoID = data.estadoID.toString()
                    this.producto.descripcion = this.utils.decodeHTMLEntities(data.descripcion)
                    this.producto.medida = data.medida
                    this.producto.unidadID = data.unidadID.toString()
                    this.producto.procedenciaID = data.procedenciaID.toString()
                    this.producto.elaboracion = (data.elaboracion === '0000-00-00') ? '' : data.elaboracion
                    this.producto.vencimiento = (data.vencimiento === '0000-00-00') ? '' : data.vencimiento
                    this.producto.stockMinimo = data.stockMinimo
                    this.producto.loteProducto = data.loteProducto
                    this.producto.porcentajeUtilidad = data.porcentajeUtilidad
                    this.producto.descuento = data.descuento

                    const imagenes = data.imagenproductos
                    imagenes.map(dato => {
                        this.fileList.push({
                            uid: dato.imagenproducto_id.toString(),
                            url: dato.location_file.toString(),
                            name: dato.file.toString(),
                            status: 'done',
                            response: '',
                            percent: 0,
                            preview: 'string',
                            originFileObj: ''
                        })
                    })

                    this.producto.estadoVisualizacion = data.estado_visualizacion
                    if (this.producto.estadoVisualizacion === 'HABILITADO') {
                        this.form.visualizacion = true
                    } else {
                        this.form.visualizacion = false
                    }

                    this.producto.estadoStock = data.estado_stock
                    if (this.producto.estadoStock === 'HABILITADO') {
                        this.form.mostrarStock = true
                    } else {
                        this.form.mostrarStock = false
                    }

                    this.producto.estadoPrecioVenta = data.estado_precio_venta
                    if (this.producto.estadoPrecioVenta === 'HABILITADO') {
                        this.form.mostrarPrecioVenta = true
                    } else {
                        this.form.mostrarPrecioVenta = false
                    }
                } else if (tipo === 'agregar') {
                    this.resetProducto()
                } else if (tipo === 'eliminar') {
                    this.resetProducto()

                    this.producto.producto_id = data.producto_id
                    this.producto.codigo = data.codigo
                    this.producto.nombre = data.nombre
                    this.producto.descripcion = data.descripcion
                }

                const valorSubCategoria = localStorage.getItem('switch-subcategoria')
                if (valorSubCategoria) {
                    if (valorSubCategoria === 'HABILITADO') {
                        this.form.subcategoria = true
                    } else {
                        this.form.subcategoria = false
                    }
                } else {
                    this.form.subcategoria = false
                }

                const valorCodigoNormal = localStorage.getItem('switch-codigo-normal')
                if (valorCodigoNormal) {
                    if (valorCodigoNormal === 'HABILITADO') {
                        this.form.codigo_normal = true
                    } else {
                        this.form.codigo_normal = false
                    }
                } else {
                    this.form.codigo_normal = false
                }

                const valorCodigoAutomatico = localStorage.getItem('switch-codigo-automatico')
                if (valorCodigoAutomatico) {
                    if (valorCodigoAutomatico === 'HABILITADO') {
                        this.form.codigo_automatico = true
                    } else {
                        this.form.codigo_automatico = false
                    }
                } else {
                    this.form.codigo_automatico = false
                }

                const valorNumeroSerie = localStorage.getItem('switch-numero-serie')
                if (valorNumeroSerie) {
                    if (valorNumeroSerie === 'HABILITADO') {
                        this.form.mostrarNumeroSerie = true
                    } else {
                        this.form.mostrarNumeroSerie = false
                    }
                } else {
                    this.form.mostrarNumeroSerie = false
                }

                const valorCodigoProducto = localStorage.getItem('switch-codigo-producto')
                if (valorCodigoProducto) {
                    if (valorCodigoProducto === 'HABILITADO') {
                        this.form.mostrarCodigoProducto = true
                    } else {
                        this.form.mostrarCodigoProducto = false
                    }
                } else {
                    this.form.mostrarCodigoProducto = false
                }

                const valorOpcionAvanzada = localStorage.getItem('switch-opcion-avanzada')
                if (valorOpcionAvanzada) {
                    if (valorOpcionAvanzada === 'HABILITADO') {
                        this.form.opciones_avanzadas = true
                    } else {
                        this.form.opciones_avanzadas = false
                    }
                } else {
                    this.form.opciones_avanzadas = false
                }

                const valorFechaVencimiento = localStorage.getItem('switch-fecha-vencimiento')
                if (valorFechaVencimiento) {
                    if (valorFechaVencimiento === 'HABILITADO') {
                        this.form.fecha_vencimiento = true
                    } else {
                        this.form.fecha_vencimiento = false
                    }
                } else {
                    this.form.fecha_vencimiento = false
                }

                const valorProductoInventariable = localStorage.getItem('switch-producto-inventariable')
                if (valorProductoInventariable) {
                    if (valorProductoInventariable === 'HABILITADO') {
                        this.form.producto_inventariable = true
                    } else {
                        this.form.producto_inventariable = false
                    }
                } else {
                    this.form.producto_inventariable = false
                }

                const valorRegistrarStock = localStorage.getItem('switch-registrar-stock')
                if (valorRegistrarStock) {
                    if (valorRegistrarStock === 'HABILITADO') {
                        this.form.registrar_stock = true
                    } else {
                        this.form.registrar_stock = false
                    }
                } else {
                    this.form.registrar_stock = false
                }
            },
    
            generarCodigoBarras () {
                const lenNumber = parseInt(this.generarlenghtcodigobarra)
                const numerosBusiness = [0, 1, 2, 3, 4]
                const numerosVerify = [0, 1, 2, 3, 4, 5, 6, 8, 9]
                const numeros = [0, 1, 2, 3, 4, 5, 6, 8]
                const numeroLenght = parseInt(this.generarlenghtcodigobarra)
                const empresa = getRandomChar(numerosBusiness, numeroLenght / 4)
                const codigo = getRandomChar(numeros, numeroLenght)
                const verificador = getRandomChar(numerosVerify, 1)
                if (lenNumber <= 2) {
                    this.producto.codigo = parseInt(`777${empresa}${codigo}${verificador}`)
                    this.GetBarCodeAgregarDefault()
                } else if (lenNumber <= 4) {
                    this.producto.codigo = parseInt(`777${empresa}${codigo}${verificador}`)
                    this.GetBarCodeAgregarDefault()
                } else if (lenNumber <= 6) {
                    this.producto.codigo = parseInt(`777${empresa}${codigo}${verificador}`)
                    this.GetBarCodeAgregarDefault()
                }
            },
                
            generarCodigoBarrasEdit () {
                const lenNumber = parseInt(this.generarlenghtcodigobarra)
                const numerosBusiness = [0, 1, 2, 3, 4]
                const numerosVerify = [0, 1, 2, 3, 4, 5, 6, 8, 9]
                const numeros = [0, 1, 2, 3, 4, 5, 6, 8]
                const numeroLenght = parseInt(this.generarlenghtcodigobarra)
                const empresa = getRandomChar(numerosBusiness, numeroLenght / 4)
                const codigo = getRandomChar(numeros, numeroLenght)
                const verificador = getRandomChar(numerosVerify, 1)
                if (lenNumber <= 2) {
                    this.producto.codigo = parseInt(`777${empresa}${codigo}${verificador}`)
                    this.GetBarCodeEditDefault()
                } else if (lenNumber <= 4) {
                    this.producto.codigo = parseInt(`777${empresa}${codigo}${verificador}`)
                    this.GetBarCodeEditDefault()
                } else if (lenNumber <= 6) {
                    this.producto.codigo = parseInt(`777${empresa}${codigo}${verificador}`)
                    this.GetBarCodeEditDefault()
                }
            },
    
            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },
    
            filterOptionCategoria (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            filterOptionSubCategoria (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },
    
            filterOptionMarca (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },
    
            filterOptionUnidad (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },
    
            filterOptionProcedencia (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            onSwitchSubCategoria (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-subcategoria'
                localStorage.setItem(nombre, valor)
                this.form.subcategoria = value
            },
    
            onSwitchCodigoNormal (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-codigo-normal'
                localStorage.setItem(nombre, valor)
                this.form.codigo_normal = value
            },

            onSwitchVisualizacion (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                this.form.visualizacion = value
                this.producto.estadoVisualizacion = valor
            },

            onSwitchStock (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                this.form.mostrarStock = value
                this.producto.estadoStock = valor
            },

            onSwitchPrecioVenta (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                this.form.mostrarPrecioVenta = value
                this.producto.estadoPrecioVenta = valor
            },
    
            onSwitchCodigoAutomatico (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-codigo-automatico'
                localStorage.setItem(nombre, valor)
                this.form.codigo_automatico = value
            },

            onSwitchNumeroSerie (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-numero-serie'
                localStorage.setItem(nombre, valor)
                this.form.mostrarNumeroSerie = value
            },

            onSwitchCodigoProducto (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-codigo-producto'
                localStorage.setItem(nombre, valor)
                this.form.mostrarCodigoProducto = value
            },
    
            onSwitchOpcionAvanzada (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-opcion-avanzada'
                localStorage.setItem(nombre, valor)
                this.form.opciones_avanzadas = value
            },
    
            onSwitchFechaVencimiento (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-fecha-vencimiento'
                localStorage.setItem(nombre, valor)
                this.form.fecha_vencimiento = value
                if (value === true) {
                    this.producto.elaboracion = moment().format('YYYY-MM-DD')
                    this.producto.vencimiento = moment().add('years', 1).format('YYYY-MM-DD')
                } else {
                    this.producto.elaboracion = ''
                    this.producto.vencimiento = ''
                }
            },
    
            onSwitchProductoInventariable (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-producto-inventariable'
                localStorage.setItem(nombre, valor)
                this.form.producto_inventariable = value
            },
    
            onSwitchRegistrarStock (value) {
                const valor = (value === true) ? 'HABILITADO' : 'DESHABILITADO'
                const nombre = 'switch-registrar-stock'
                localStorage.setItem(nombre, valor)
                this.form.registrar_stock = value
            },
    
            GetBarCode ($event) {
                const formData = new FormData()
                const ecode = new EuropioCode()
                formData.append('producto_code', ecode.encode(btoa(this.producto.codigo)))
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    if ($event.keyCode === 13) {
                        axios.post(URI_PRODUCTO_BARCODE, formData)
                        .then(response => {
                            this.codigo_barra = response.data.barcode
                            this.codigo_observacion = response.data.codigo
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    }
                } else {
                    $event.preventDefault()
                }
            },
                
            GetBarCodeAgregarDefault () {
                const formData = new FormData()
                const ecode = new EuropioCode()
                formData.append('producto_code', ecode.encode(btoa(this.producto.codigo)))
                axios.post(URI_PRODUCTO_BARCODE, formData)
                .then(response => {
                    this.codigo_barra = response.data.barcode
                    this.codigo_observacion = response.data.codigo
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
    
            GetBarCodeEditDefault () {
                const formData = new FormData()
                const ecode = new EuropioCode()
                formData.append('producto_code', ecode.encode(btoa(this.producto.codigo)))
                axios.post(URI_PRODUCTO_BARCODE, formData)
                .then(response => {
                    this.codigo_barra = response.data.barcode
                    this.codigo_observacion = response.data.codigo
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
    
            GetBarCodeEdit ($event) {
                const formData = new FormData()
                const ecode = new EuropioCode()
                formData.append('producto_code', ecode.encode(btoa(this.producto.codigo)))
                if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode)) || $event.keyCode === 13) {
                    if ($event.keyCode === 13) {
                        axios.post(URI_PRODUCTO_BARCODE, formData)
                        .then(response => {
                            this.codigo_barra = response.data.barcode
                            this.codigo_observacion = response.data.codigo
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    }
                } else {
                    $event.preventDefault()
                }
            },
    
            GetCode ($event) {
                const formData = new FormData()
                const ecode = new EuropioCode()
                formData.append('producto_code', ecode.encode(btoa(this.producto.codigo)))
                if ($event.keyCode === 13) {
                    axios.post(URI_PRODUCTO_CODE, formData)
                    .then(response => {
                        this.codigo_observacion = response.data.observacion
                    })
                    .catch(error => {
                        utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                    })
                }
            },

            getCategorias () {
                this.getCategoria(this.producto.categoriaID) 
                .then(response => {
                    this.producto.categoriaID = response.data.categoria_id
                })
            },
    
            getUnidad () {
                axios.get(URI_UNIDAD_LISTAR)
                .then(response => {
                    this.lista_unidades = response.data.lista
                    this.producto.unidadID = response.data.unidad_id
                })
            },
    
            getMarca () {
                axios.get(URI_MARCA_LISTAR)
                .then(response => {
                    this.lista_marcas = response.data.lista
                    this.producto.marcaID = response.data.marca_id
                })
            },
    
            getProcedencia () {
                axios.get(URI_PROCEDENCIA_LISTAR)
                .then(response => {
                    this.lista_procedencias = response.data.lista
                    this.producto.procedenciaID = response.data.procedencia_id
                })
            },
    
            getEstado () {
                axios.get(URI_ESTADO_LISTAR)
                .then(response => {
                    this.lista_estados = response.data
                })
            },
    
            getAlmacen () {
                axios.get(URI_ALMACEN_LISTAR_LAST)
                .then(response => {
                    this.lista_almacenes = response.data.lista
                    this.producto.almacenID = this.almacenid.toString()
                })
            },
    
            getProveedor () {
                axios.get(URI_PROVEEDOR_LISTAR_LAST)
                .then(response => {
                    this.lista_proveedores = response.data.lista
                    this.producto.proveedorID = response.data.proveedor_id
                })
            },

            listaSubCategoria () {
                this.spinnerloading = true
                this.getSubCategoria(this.producto.categoriaID) 
                .then(response => {
                    if (response.status === 200) {
                        this.spinnerloading = false
                    }
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },
    
            guardarUnidad () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.producto.nombreUnidad))
                        axios.post(URI_UNIDAD_GUARDAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getUnidad()
                                this.spinnerloading = false
                                this.modalUnidad = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },
    
            guardarCategoria () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.producto.nombreCategoria))
                        axios.post(URI_CATEGORIA_GUARDAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getCategoria()
                                this.spinnerloading = false
                                this.modalCategoria = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },

            guardarSubCategoria () {
                this.spinnerloading = true
				this.create(this.producto)
                .then(response => {
                    if (response.status === 201) {
                        this.listaSubCategoria()
					    utils.openNotificationWithIcon('success', 'Exito', response.data, 'topRight')
                        this.modalSubCategoria = false
                        this.spinnerloading = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKey++
            },
    
            guardarMarca () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.producto.nombreMarca))
                        axios.post(URI_MARCA_GUARDAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getMarca()
                                this.spinnerloading = false
                                this.modalMarca = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },
    
            guardarProcedencia () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.producto.nombreProcedencia))
                        axios.post(URI_PROCEDENCIA_GUARDAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getProcedencia()
                                this.spinnerloading = false
                                this.modalProcedencia = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },
    
            guardarProveedor () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.producto.nombreProveedor))
                        axios.post(URI_PROVEEDOR_GUARDAR_POST, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getProveedor()
                                this.spinnerloading = false
                                this.modalProveedor = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },
    
            guardarAlmacen () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('nombre', ecode.encode(this.producto.nombreAlmacen))
                        axios.post(URI_ALMACEN_GUARDAR_POST, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.getAlmacen()
                                this.spinnerloading = false
                                this.modalAlmacen = false
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKey++
            },
    
            onSelectProveedor (value) {
                this.producto.proveedorID = value
            },

            onSelectCategoria (value) {
                this.producto.categoriaID = value.key
                this.producto.nombreCategoria = value.label
                if (this.form.subcategoria === true) {
                    this.producto.subcategoriaID = 'Seleccionar'
                    this.producto.nombreSubCategoria = ''
                    this.listaSubCategoria()
                    this.modalVisualSubCategoria = true
                }
            },

            onSelectSubCategoria (value) {
                this.producto.subcategoriaID = value.key
                this.producto.nombreSubCategoria = value.label
                this.modalVisualSubCategoria = false
            },
    
            onSelectMarca (value) {
                this.producto.marcaID = value
            },
    
            onSelectEstado (value) {
                this.producto.estadoID = value
            },
    
            onSelectUnidad (value) {
                this.producto.unidadID = value
            },
    
            onSelectProcedencia (value) {
                this.producto.procedenciaID = value
            },
    
            onDateElaboracion (date, dateString) {
                this.producto.elaboracion = dateString
            },
    
            onDateVencimiento (date, dateString) {
                this.producto.vencimiento = dateString
            },
    
            onChangeStockMinimo (value) {
                this.producto.stockMinimo = value
            },

            onChangeDescuento (value) {
                this.producto.descuento = value
            },
    
            onChangeMargenGanancia (value) {
                this.producto.porcentajeUtilidad = value
            },
    
            onSelectSucursal (value) {
                this.producto.almacenID = value
            },
    
            onChangeCosto (value) {
                this.producto.costo = value
            },
    
            onChangeCantidad (value) {
                this.producto.cantidad = value
            },
    
            onChangePrecioVenta (value, index) {
                this.lista_precios_venta[index].precioValor = value
            },
    
            onChangePrecioVentaFacturado (value, index) {
                this.lista_precios_venta[index].precioFacturado = value
            },
    
            abriModalSecundary (tipo) {
                switch (tipo) {
                    case 'unidad':
                        this.modalUnidad = true
                        break
                    case 'categoria':
                        this.modalCategoria = true
                        break
                    case 'marca':
                        this.modalMarca = true
                        break
                    case 'procedencia':
                        this.modalProcedencia = true
                        break
                    case 'almacen':
                        this.modalAlmacen = true
                        break
                    case 'proveedor':
                        this.modalProveedor = true
                        break
                    case 'subcategoria':
                        this.modalSubCategoria = true
                        break
                    default:
                }
            },
    
            /** UPLOAD DE IMAGENES PRODUCTO */
    
            beforeUpload (file) {
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
                if (!isJpgOrPng) {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'Debe ser un archivo de imagen...', 'topRight')
                }
                const isLt1M = file.size / 5000 / 5000 < 3
                if (!isLt1M) {
                    utils.openNotificationWithIcon('info', 'Mensaje : ', 'La imagen debe tener un tamaño maximo a 5 MB. !', 'topRight')
                }
                return isJpgOrPng && isLt1M
            },
    
            handleCancel () {
                this.previewVisible = false
            },
    
            async handlePreview (file) {
                if (!file.url && !file.preview) {
                    file.preview = await getBase64File(file.originFileObj)
                }
                this.previewImage = file.url || file.preview
                this.previewVisible = true
            },
    
            handleChange ({ fileList }) {
                this.fileList = fileList
            },
    
            guardarImagenProducto (info) {
                const self = this
                // eslint-disable-next-line no-new
                new Compressor(info.file, {
                    quality: 0.6,
                    success (result) {
                        const file = blob2file(result, result.name)
                        const formData = new FormData()
                        const ecode = new EuropioCode()
                        formData.append('productoID', self.producto.producto_id)
                        formData.append('file', file)
                        axios.post(URI_IMAGENPRODUCTO_GUARDAR, formData, {
                            'content-type': 'multipart/form-data',
                            'onUploadProgress': (e) => {
                                info.onProgress({ percent: (e.loaded / e.total) * 100 })
                            }
                        })
                        .then(response => {
                            if (response.status === 201) {
                                info.onSuccess(response, info.file)
                                self.$emit('successProducto', 'Realizado con Exito la subida de Imagen')
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Se ha registrado correctamente el archivo...', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    },
                    error (error) {
                        utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                    }
                })
            },
    
            handleRemove (info) {
                const self = this
                this.spinnerloading = true
                const formData = new FormData()
                const ecode = new EuropioCode()
                formData.append('imagenproductoID', info.uid)
                axios.post(URI_IMAGENPRODUCTO_ELIMINAR, formData)
                .then(response => {
                    if (response.status === 201) {
                        this.spinnerloading = false
                        self.$emit('successProducto', 'Realizado con Exito')
                        Swal.fire('Éxito!', response.data.message, 'success')
                    }
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
    
            filesSelected (fileRecord) {
                const $this = this
                const validFileRecords = fileRecord.filter((file) => !file.error)
                const data = Object.values(validFileRecords).filter(x => typeof x !== 'undefined').shift()

                // eslint-disable-next-line no-new
                new Compressor(data.file, {
                    quality: 0.6,
                    success (result) {
                        const file = blob2file(result, result.name)
                        $this.producto.files.push(file)
                    },
                    error (err) {
                        console.log(err)
                    }
                })
            },
    
            onBeforeDelete (fileRecord) {
                const i = this.producto.files.indexOf(fileRecord)
                if (i !== -1) {
                    this.producto.files.splice(i, 1)
                } else {
                    this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
                }
            },
    
            fileDeleted (fileRecord) {
            },
    
            guardarProducto () {
                Swal.fire({
                    title: 'Desea Agregar y Guardar?',
                    text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerLoadingIndicator = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('codigo', ecode.encode(btoa(this.producto.codigo)))
                        formData.append('codigoProducto', ecode.encode(btoa(this.producto.codigoProducto)))
                        formData.append('numeroSerie', ecode.encode(btoa(this.producto.numeroSerie)))
                        formData.append('nombre', ecode.encode(this.producto.nombre))
                        formData.append('descripcion', ecode.encode(this.producto.descripcion))
                        formData.append('medida', ecode.encode(this.producto.medida))
                        formData.append('elaboracion', (this.producto.elaboracion === null) ? '' : ecode.encode(this.producto.elaboracion))
                        formData.append('vencimiento', (this.producto.vencimiento === null) ? '' : ecode.encode(this.producto.vencimiento))
                        formData.append('loteProducto', ecode.encode(this.producto.loteProducto.toString()))
                        formData.append('porcentajeUtilidad', parseInt(this.producto.porcentajeUtilidad))
                        formData.append('stockMinimo', parseInt(this.producto.stockMinimo))
                        formData.append('marcaID', this.producto.marcaID)
                        formData.append('unidadID', this.producto.unidadID)
                        formData.append('categoriaID', this.producto.categoriaID)
                        formData.append('subcategoriaID', (this.producto.subcategoriaID === 'Seleccionar') ? 0 : this.producto.subcategoriaID)
                        formData.append('procedenciaID', this.producto.procedenciaID)
                        formData.append('proveedorID', this.producto.proveedorID)
                        formData.append('estadoID', this.producto.estadoID)
                        formData.append('almacenID', this.producto.almacenID)
                        formData.append('costo', (this.producto.costo === null) ? 0.00 : ecode.encode(this.producto.costo.toString()))
                        formData.append('cantidad', (this.producto.cantidad === null) ? 0 : parseInt(this.producto.cantidad))
                        formData.append('listaPrecioVenta', ecode.encode(JSON.stringify(this.lista_precios_venta)))
                        formData.append('estadoVisualizacion', this.producto.estadoVisualizacion)
                        formData.append('estadoStock', this.producto.estadoStock)
                        formData.append('estadoPrecioVenta', this.producto.estadoPrecioVenta)
                        formData.append('descuento', this.producto.descuento)

                        if (this.producto.files) {
                            for (let i = 0; i < this.producto.files.length; i++) {
                                formData.append('files[]', this.producto.files[i])
                            }
			            }

                        axios.post(URI_PRODUCTO_GUARDAR, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: e => {
                                if (e.lengthComputable) {
                                    this.producto.progress_upload = (e.loaded / e.total) * 100
                                }
                            }
                        })
                        .then(response => {
                            if (response.status === 201) {
                                this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
                                this.getListaScrollMasivoProducto(this.consulta)
                                this.getListaScrollMasivoProductoStock(this.consulta)

                                this.spinnerLoadingIndicator = false
                                this.$emit('successProducto', response.data.message)
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                                this.resetProducto()
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('info', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                
                this.buttonKeyGuardarProducto++
            },
    
            editarProducto () {
                Swal.fire({
                    title: 'Desea Actualizar y Guardar?',
                    text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const ecode = new EuropioCode()
                        const formData = new FormData()
                        formData.append('productoID', this.producto.producto_id)
                        formData.append('codigo', ecode.encode(btoa(this.producto.codigo)))
                        formData.append('codigoProducto', ecode.encode(btoa(this.producto.codigoProducto)))
                        formData.append('numeroSerie', ecode.encode(btoa(this.producto.numeroSerie)))
                        formData.append('nombre', ecode.encode(this.producto.nombre))
                        formData.append('descripcion', ecode.encode(this.producto.descripcion))
                        formData.append('medida', ecode.encode(this.producto.medida))
                        formData.append('elaboracion', (this.producto.elaboracion === null) ? '' : ecode.encode(this.producto.elaboracion))
                        formData.append('vencimiento', (this.producto.vencimiento === null) ? '' : ecode.encode(this.producto.vencimiento))
                        formData.append('loteProducto', ecode.encode(this.producto.loteProducto.toString()))
                        formData.append('porcentajeUtilidad', parseInt(this.producto.porcentajeUtilidad))
                        formData.append('stockMinimo', parseInt(this.producto.stockMinimo))
                        formData.append('marcaID', this.producto.marcaID)
                        formData.append('unidadID', this.producto.unidadID)
                        formData.append('categoriaID', this.producto.categoriaID)
                        formData.append('subcategoriaID', (this.producto.subcategoriaID === 'Seleccionar') ? 0 : this.producto.subcategoriaID)
                        formData.append('procedenciaID', this.producto.procedenciaID)
                        formData.append('proveedorID', this.producto.proveedorID)
                        formData.append('estadoID', this.producto.estadoID)
                        formData.append('estadoVisualizacion', this.producto.estadoVisualizacion)
                        formData.append('estadoStock', this.producto.estadoStock)
                        formData.append('estadoPrecioVenta', this.producto.estadoPrecioVenta)
                        formData.append('descuento', this.producto.descuento)

                        axios.post(URI_PRODUCTO_EDITAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
                                this.getListaScrollMasivoProducto(this.consulta)
                                this.getListaScrollMasivoProductoStock(this.consulta)

                                this.spinnerloading = false
                                this.$emit('successProducto', response.data.message)
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                                this.resetProducto()
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })

                this.buttonKeyActualizarProducto++
            },
    
            eliminarProducto () {
                Swal.fire({
                    title: 'Desea Eliminar este Producto ?',
                    text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        const formData = new FormData()
                        formData.append('productoID', this.producto.producto_id)
                        axios.post(URI_PRODUCTO_ELIMINAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
                                this.getListaScrollMasivoProducto(this.consulta)
                                this.getListaScrollMasivoProductoStock(this.consulta)

                                this.spinnerloading = false
                                this.$emit('successProducto', response.data.message)
                                utils.openNotificationWithIcon('success', 'Mensaje : ', 'Haz realizado con éxito el proceso de la información', 'topRight')
                                this.resetProducto()
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })

                this.buttonKeyEliminarProducto++
            },

            /** PRECIO DE VENTAS */
            
            onSelectTipoPrecioVenta (event) {
                const self = this
                this.asignacion.tipoprecioventaID = event.target.value.tipoprecioventa_id
                this.asignacion.nombreTipoPrecioVenta = event.target.value.nombre

                if (Object.keys(this.lista_precios_venta).length === 0) {
                    this.lista_precios_venta.push({
                        tipoprecioventaID: this.asignacion.tipoprecioventaID,
                        nombreTipoPrecioVenta: this.asignacion.nombreTipoPrecioVenta,
                        precioValor: '0.00',
                        precioFacturado: '0.00'
                    })
                } else {
                    let exists = false
                    for (const element of this.lista_precios_venta) {
                        if (parseInt(element.tipoprecioventaID) === parseInt(this.asignacion.tipoprecioventaID)) {
                            exists = true
                            break
                        }
                    }

                    if (!exists) {
                        this.lista_precios_venta.push({
                            tipoprecioventaID: this.asignacion.tipoprecioventaID,
                            nombreTipoPrecioVenta: this.asignacion.nombreTipoPrecioVenta,
                            precioValor: '0.00',
                            precioFacturado: '0.00'
                        })
                    }
                }
            },

            deleteItemTipoPrecioVenta (index) {
                this.lista_precios_venta.splice(index, 1)
                if (this.lista_precios_venta.length === 0) {
                    this.asignacion.tipoprecioventa = []
                }
            }
        }
    }
</script>

<style>
    @import "~vue-wysiwyg/dist/vueWysiwyg.css";

    .ant-input-number-lg {
        padding: 0;
        font-size: 22px;
    }

    .radio-button {
        margin-right: 10px; /* Ajusta el valor según tus necesidades */
    }

    .texto-10 {
        font-size:10px; 
        text-align:center; 
        color: var(--element); 
        font-weight:700;
    }

    .texto-12 {
        font-size:12px; 
        text-align:center; 
        color: var(--element); 
        font-weight:700;
    }

    .texto-14 {
        font-size:14px; 
        text-align:center; 
        color: var(--element); 
        font-weight:700;
    }

    .texto-base-12 {
        font-size:12px; 
        text-align:center; 
        color: #8C8C8C; 
        font-weight:700;
    }
</style>